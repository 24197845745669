import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../firebaseConfig';
import LegalMentions from './LegalMentions';
import PrivacyPolicy from './PrivacyPolicy';
import './TermsAcceptance.css';

function TermsAcceptance() {
  const [isScrolledToBottomLegal, setIsScrolledToBottomLegal] = useState(false);
  const [isScrolledToBottomPrivacy, setIsScrolledToBottomPrivacy] = useState(false);
  const legalContentRef = useRef(null);
  const privacyContentRef = useRef(null);
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const db = getFirestore();

  const handleScroll = (ref, setIsScrolledToBottom) => {
    const { scrollTop, scrollHeight, clientHeight } = ref.current;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      setIsScrolledToBottom(true);
    }
  };

  const handleAccept = async () => {
    if (isScrolledToBottomLegal && isScrolledToBottomPrivacy && currentUser) {
      const userRef = doc(db, 'users', currentUser.uid);
      try {
        await updateDoc(userRef, {
          termsAccepted: true,
        });
        navigate('/profile-form');
      } catch (error) {
        console.error('Error updating terms acceptance:', error);
      }
    }
  };

  return (
    <div className="terms-acceptance-container">
      <p className="read-instruction">
        Please read and scroll through the Legal Mentions and Privacy Policy sections below to accept the terms.
      </p>

      {/* Section des mentions légales */}
      <div className="terms-section">
        <h3>Legal Mentions</h3>
        <div
          className="terms-content"
          ref={legalContentRef}
          onScroll={() => handleScroll(legalContentRef, setIsScrolledToBottomLegal)}
        >
          <LegalMentions />
        </div>
      </div>

      {/* Section de la politique de confidentialité */}
      <div className="terms-section">
        <h3>Privacy Policy</h3>
        <div
          className="terms-content"
          ref={privacyContentRef}
          onScroll={() => handleScroll(privacyContentRef, setIsScrolledToBottomPrivacy)}
        >
          <PrivacyPolicy />
        </div>
      </div>

      <button
        onClick={handleAccept}
        disabled={!(isScrolledToBottomLegal && isScrolledToBottomPrivacy)}
        className={`btn-primary ${isScrolledToBottomLegal && isScrolledToBottomPrivacy ? '' : 'disabled'}`}
      >
        I Accept
      </button>
    </div>
  );
}

export default TermsAcceptance;