// src/components/TwoFactorAuth.js
import React, { useState } from 'react';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

const TwoFactorAuth = ({ setUser }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationId, setVerificationId] = useState(null);
  const auth = getAuth();

  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': () => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        onSignInSubmit();
      }
    }, auth);
  };

  const onSignInSubmit = (e) => {
    e.preventDefault();
    setUpRecaptcha();
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        setVerificationId(confirmationResult.verificationId);
      }).catch((error) => {
        console.error(error);
      });
  };

  const onSubmitCode = (e) => {
    e.preventDefault();
    const credential = getAuth.PhoneAuthProvider.credential(verificationId, verificationCode);
    auth.currentUser.linkWithCredential(credential).then((usercred) => {
      const user = usercred.user;
      console.log("Account linking success", user);
      setUser(user);
    }).catch((error) => {
      console.error("Error during linking", error);
    });
  };

  return (
    <div className="two-factor-auth">
      <h2>Two Factor Authentication</h2>
      {!verificationId ? (
        <form onSubmit={onSignInSubmit}>
          <div id="recaptcha-container"></div>
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input type="text" id="phoneNumber" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
          </div>
          <button type="submit" className="btn btn-primary">Send Verification Code</button>
        </form>
      ) : (
        <form onSubmit={onSubmitCode}>
          <div className="form-group">
            <label htmlFor="verificationCode">Verification Code</label>
            <input type="text" id="verificationCode" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} />
          </div>
          <button type="submit" className="btn btn-primary">Verify Code</button>
        </form>
      )}
    </div>
  );
};

export default TwoFactorAuth;