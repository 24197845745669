import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import 'leaflet/dist/leaflet.css';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// Log pour vérifier que l'application commence à se charger
console.log("L'application commence à se charger");

// Fonction pour enregistrer le token dans Firestore
const saveDeviceTokenToFirestore = async (token) => {
  const auth = getAuth();
  const currentUser = auth.currentUser;

  if (!currentUser) {
    console.log("L'utilisateur n'est pas connecté. Le token ne sera pas enregistré.");
    return;
  }

  console.log("Utilisateur connecté :", currentUser.uid);  // Log pour vérifier que l’utilisateur est connecté

  const db = getFirestore();
  const userDocRef = doc(db, 'users', currentUser.uid);

  try {
    await updateDoc(userDocRef, { deviceToken: token });
    console.log("Device token enregistré avec succès dans Firestore");
  } catch (error) {
    console.error("Erreur lors de l'enregistrement du device token :", error);
  }
};

const initializePushNotifications = async () => {
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive === 'granted') {
      await PushNotifications.register();
  } else {
      console.error("L'utilisateur a refusé les permissions de notification.");
  }

  // Écouter les événements de notification
  PushNotifications.addListener('registration', async (token) => {
    console.log('FCM Token:', token.value);  // Log pour confirmer la réception du token
    await saveDeviceTokenToFirestore(token.value);
  });

  PushNotifications.addListener('registrationError', (err) => {
    console.error('Erreur d’enregistrement : ', err.error);
  });

  PushNotifications.addListener('pushNotificationReceived', (notification) => {
    console.log('Notification reçue:', notification);
  });

  PushNotifications.addListener('pushNotificationActionPerformed', (action) => {
    console.log('Action de notification:', action);
  });
};

// Initialiser les notifications push si l'application est native
if (Capacitor.isNativePlatform()) {
  initializePushNotifications();
}

// Rendu de l'application React
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Log pour vérifier que l'application React est rendue avec succès
console.log("Application rendue avec succès");

// Désactivation des Service Workers
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    registrations.forEach(function(registration) {
      registration.unregister();
    });
  });
  console.log('Tous les Service Workers ont été désinscrits.');
} else {
  console.log('Les Service Workers ne sont pas supportés ou déjà désactivés.');
}

reportWebVitals();
