import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, collection, addDoc, query, where, onSnapshot, orderBy, Timestamp, doc, getDoc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../firebaseConfig';
import ReportAbuseModal from './ReportAbuseModal'; // Importer le composant ReportAbuseModal
import './ChatRoom.css';
import { Link } from 'react-router-dom';

function ChatRoom() {
  const { conversationId } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isReportModalOpen, setReportModalOpen] = useState(false);
  const [reportedUserId, setReportedUserId] = useState(''); // Nouveau state pour l'ID de l'utilisateur signalé
  const { currentUser } = useAuth();
  const [userProfiles, setUserProfiles] = useState({});
  const db = getFirestore();
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (!currentUser) return;

    const q = query(
      collection(db, 'messages'),
      where('chatId', '==', conversationId),
      orderBy('timestamp', 'asc')
    );

    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const msgs = [];
      for (const docSnapshot of querySnapshot.docs) {
        const messageData = docSnapshot.data();

        // Marquer le message comme lu par l'utilisateur actuel s'il ne l'a pas encore fait
        if (!messageData.readBy || !messageData.readBy.includes(currentUser.uid)) {
          await updateDoc(docSnapshot.ref, {
            readBy: messageData.readBy ? [...messageData.readBy, currentUser.uid] : [currentUser.uid],
          });
        }

        msgs.push({
          id: docSnapshot.id,
          ...messageData
        });
      }
      setMessages(msgs);
    });

    // Marquer la conversation comme lue lors de chaque chargement des messages
    const markAsRead = async () => {
      const conversationRef = doc(db, 'chats', conversationId);
      await updateDoc(conversationRef, {
        [`readBy.${currentUser.uid}`]: Timestamp.fromDate(new Date())
      });
    };

    markAsRead();  // Appeler une fois pour initialiser lors de l'entrée dans la conversation

    const intervalId = setInterval(markAsRead, 5000);  // Appeler toutes les 5 secondes

    return () => {
      unsubscribe();
      clearInterval(intervalId);
    };
  }, [currentUser, db, conversationId]);

  useEffect(() => {
    const fetchUserProfiles = async () => {
      try {
        const convDoc = await getDoc(doc(db, 'chats', conversationId));
        
        if (!convDoc.exists()) {
          console.error('Conversation document not found');
          return;
        }

        const conversationData = convDoc.data();
        
        if (!conversationData || !conversationData.members) {
          console.error('Conversation data or members undefined');
          return;
        }

        const members = conversationData.members;
        const fetchedProfiles = {};

        const promises = members.map(async (memberId) => {
          const userDoc = await getDoc(doc(db, 'users', memberId));
          if (userDoc.exists()) {
            fetchedProfiles[memberId] = userDoc.data();
          }
        });

        await Promise.all(promises);
        setUserProfiles(fetchedProfiles);
      } catch (error) {
        console.error('Error fetching user profiles:', error);
      }
    };

    fetchUserProfiles();
  }, [db, conversationId]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;

    // Ajoute le nouveau message à la collection "messages"
    const newMessageRef = await addDoc(collection(db, 'messages'), {
      chatId: conversationId,
      text: newMessage,
      timestamp: Timestamp.fromDate(new Date()),
      senderId: currentUser.uid,
      readBy: [currentUser.uid], // Marquer le message comme lu par l'envoyeur
    });

    // Met à jour le champ lastMessage dans la collection "chats"
    const conversationRef = doc(db, 'chats', conversationId);
    await updateDoc(conversationRef, {
      lastMessage: {
        id: newMessageRef.id,
        text: newMessage,
        timestamp: Timestamp.fromDate(new Date()),
        senderId: currentUser.uid
      },
      [`readBy.${currentUser.uid}`]: Timestamp.fromDate(new Date())
    });

    setNewMessage('');
  };

  const handleReportClick = () => {
    // Trouver l'ID d'un utilisateur autre que l'utilisateur actuel
    const otherUserId = Object.keys(userProfiles).find(id => id !== currentUser.uid);
    if (otherUserId) {
      setReportedUserId(otherUserId);
      setReportModalOpen(true);
    } else {
      alert('No user to report.');
    }
  };

  const handleCloseReportModal = () => {
    setReportModalOpen(false);
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div className="chat-room">
      {/* Bouton Report au-dessus des messages */}
      <div className="chat-header">
  <div className="chat-header-left">
    {/* Affichage de la photo de profil et du nom */}
    {userProfiles &&
      Object.keys(userProfiles).map((id) =>
        id !== currentUser.uid ? (
          <div key={id} className="chat-header-user-info">
            <Link to={`/user-profile/${id}`}>
  <img
    src={userProfiles[id].profile.profilePicture || 'path_to_default_image.jpg'}
    alt="Profile"
    className="chat-header-profile-pic"
  />
</Link>
            <h2>
              {`${userProfiles[id].profile.firstName} ${userProfiles[id].profile.lastName.charAt(0)}.`}
            </h2>
          </div>
        ) : null
      )}
  </div>

  {/* Nouveau nom pour le bouton de signalement */}
  <button className="flag-button" onClick={handleReportClick}>
    Report
  </button>
</div>

      <div className="messages">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`message ${message.senderId === currentUser.uid ? 'sent' : 'received'}`}
          >
            <div className="sender-info">
              {userProfiles[message.senderId] ? `${userProfiles[message.senderId].profile.firstName} ${userProfiles[message.senderId].profile.lastName.charAt(0)}.` : 'Utilisateur'}
            </div>
            <div className="message-info">
              {new Date(message.timestamp.toDate()).toLocaleString()}
            </div>
            {message.text}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <form className="chat-input" onSubmit={handleSendMessage}>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Write your message..."
        />
        <button type="submit">Send</button>
      </form>

      {/* Modal de signalement */}
      {isReportModalOpen && reportedUserId && (
        <ReportAbuseModal
          isOpen={isReportModalOpen}
          onClose={handleCloseReportModal}
          reportedUserId={reportedUserId}
          chatId={conversationId}
        />
      )}
    </div>
  );
}

export default ChatRoom;