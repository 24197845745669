// src/Pages/SettingsPage.js
import React from 'react';
import { NavLink, Route, Routes, useNavigate } from 'react-router-dom';
import { getAuth, signOut, deleteUser } from 'firebase/auth';
import { getFirestore, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import LegalMentions from './LegalMentions';
import PrivacyPolicy from './PrivacyPolicy';
import './SettingsPage.css';
import { useAuth } from '../firebaseConfig';

function SettingsPage({ setUser }) {
  const navigate = useNavigate();
  const auth = getAuth();
  const { currentUser } = useAuth();
  const db = getFirestore();

  const handleLogout = () => {
    console.log('Logout button clicked');
    signOut(auth)
      .then(() => {
        setUser(null);
        navigate('/');
      })
      .catch((error) => {
        console.error('Error during sign out:', error);
      });
  };

  const handleDeactivateAccount = async () => {
    if (!currentUser) return;

    const confirmDeactivate = window.confirm(
      'Are you sure you want to deactivate your account? You can reactivate it later by logging back in.'
    );

    if (confirmDeactivate) {
      try {
        const userDocRef = doc(db, 'users', currentUser.uid);
        await updateDoc(userDocRef, {
          active: false,
          onlineStatus: 'offline',
        });
        alert('Your account has been deactivated.');
        handleLogout();
      } catch (error) {
        console.error('Error deactivating account:', error);
        alert('Failed to deactivate account. Please try again.');
      }
    }
  };

  const handleDeleteAccount = async () => {
    if (!currentUser) return;

    const confirmDelete = window.confirm(
      'Are you sure you want to delete your account? This action is irreversible and will permanently remove all your data.'
    );

    if (confirmDelete) {
      try {
        const userDocRef = doc(db, 'users', currentUser.uid);
        await deleteDoc(userDocRef); 
        await deleteUser(currentUser); 
        alert('Your account has been deleted.');
        navigate('/'); 
      } catch (error) {
        console.error('Error deleting account:', error);
        alert('Failed to delete account. Please try again.');
      }
    }
  };

  return (
    <div className="settings-container">
      <h1 className="settings-title">Settings</h1>
      <nav className="settings-nav">
        <NavLink
          to="legal-mentions"
          className={({ isActive }) => (isActive ? 'active' : 'settings-link')}
        >
          Legal Mentions
        </NavLink>
        <NavLink
          to="privacy-policy"
          className={({ isActive }) => (isActive ? 'active' : 'settings-link')}
        >
          Privacy Policy
        </NavLink>
      </nav>

      <div className="settings-content">
        <Routes>
          <Route path="legal-mentions" element={<LegalMentions />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </div>

      <div className="settings-actions">
        <button onClick={handleLogout} className="action-button logout-button">
          Log Out
        </button>
        <button onClick={handleDeactivateAccount} className="action-button deactivate-button">
          Deactivate Account
        </button>
        <button onClick={handleDeleteAccount} className="action-button delete-button">
          Delete Account
        </button>
      </div>
    </div>
  );
}

export default SettingsPage;