// src/Pages/EmailVerificationPage.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';

function EmailVerificationPage() {
  const navigate = useNavigate();

  return (
    <div className="email-verification-container">
      <h2>Verify Your Email</h2>
      <p>
        A verification link has been sent to your email address. Please check your mailbox and click on the link to complete your registration.
      </p>
      <button onClick={() => navigate('/login')} className="btn btn-primary">
        Back to Login
      </button>
    </div>
  );
}

export default EmailVerificationPage;