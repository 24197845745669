// src/Pages/SignUpEmail.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore'; // Importation des fonctions nécessaires pour Firestore
import './SignUpEmail.css';

function SignUpEmail({ setUser }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0); // Indicateur de force du mot de passe
  const [error, setError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false); // État pour afficher/masquer le mot de passe
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isRegistered, setIsRegistered] = useState(false); // État pour contrôler l'affichage du bouton "Back"
  const navigate = useNavigate();
  const db = getFirestore(); // Initialisation de Firestore

  const handleSignUpWithEmail = async () => {
    if (password !== confirmPassword) {
      setPasswordError('Passwords do not match');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      setUser(user);

      // Créer un document utilisateur dans Firestore
      const userRef = doc(db, 'users', user.uid);
      await setDoc(userRef, {
        id: user.uid, // Ajouter l'UID de l'utilisateur ici
        email: user.email,
        firstName,
        lastName,
        verified: false,
        isProfileComplete: false,
        createdAt: new Date(),
        onlineStatus: 'offline',
        tutorialCompleted: false, // Initialiser à false par défaut
      });

      // Envoi du mail de bienvenue en appelant ton script PHP
      await fetch('https://mail.meevel.app/send_email.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          'email': user.email,
          'username': firstName,  // Nom de l'utilisateur
          'type': 'welcome'  // Type de l'e-mail (doit correspondre à une valeur dans votre switch PHP)
        }),
      });

      // Envoi de l'e-mail de vérification Firebase
      await sendEmailVerification(user);

      

      // Mettre à jour l'état pour l'inscription réussie
      setIsRegistered(true);

      // Rediriger vers la page de connexion avec un message approprié
      navigate('/login-email', { state: { message: 'Please verify your email before logging in.' } });
    } catch (error) {
      setError(error.message);
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    const strength = calculatePasswordStrength(newPassword);
    setPasswordStrength(strength);

    if (passwordError) setPasswordError('');
  };

  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (/[A-Z]/.test(password)) strength++;
    if (/[0-9]/.test(password)) strength++;
    if (/[^A-Za-z0-9]/.test(password)) strength++;
    return strength;
  };

  const handleBackClick = () => {
    navigate('/'); // Redirige vers la page d'accueil ou la page souhaitée
  };

  return (
    <div className="sign-up-email-container">
      <h2>Sign Up with Email</h2>
      {error && <p className="error-message">{error}</p>}
      {passwordError && <p className="error-message">{passwordError}</p>}
      <input
        type="text"
        placeholder="First Name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />
      <input
        type="text"
        placeholder="Last Name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      />
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <div className="password-input-container">
        <input
          type={showPassword ? 'text' : 'password'}
          placeholder="Password"
          value={password}
          onChange={handlePasswordChange}
        />
        <button
          type="button"
          className="show-password-button"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? 'Hide' : 'Show'}
        </button>
      </div>
      <div className="password-strength-bar">
        <div className={`strength-${passwordStrength}`}></div>
      </div>
      <div className="password-input-container">
        <input
          type={showPassword ? 'text' : 'password'}
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <button
          type="button"
          className="show-password-button"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? 'Hide' : 'Show'}
        </button>
      </div>
      <button onClick={handleSignUpWithEmail}>Register with Email</button>
      {isRegistered && (
        <button onClick={handleBackClick} className="btn-back">
          Back
        </button>
      )}
    </div>
  );
}

export default SignUpEmail;