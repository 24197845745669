import React, { useState } from 'react';
import './GeolocationModal.css';

const GeolocationModal = ({ onClose, onSetLocation }) => {
  const [city, setCity] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const handleCityInput = async (input) => {
    setCity(input);

    if (input.length > 2) {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(input)}&limit=5`
        );
        const data = await response.json();
        setSuggestions(data);
      } catch (error) {
        console.error('Erreur lors de la récupération des suggestions de ville:', error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    const { lat, lon, display_name: cityName } = suggestion;

    setCity(cityName);
    setSuggestions([]);
    onSetLocation(parseFloat(lat), parseFloat(lon), cityName);
    onClose();
  };

  const handleUseCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          try {
            const response = await fetch(
              `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
            );
            const data = await response.json();

            if (data && data.address) {
              const cityName = data.address.city || data.address.town || data.address.village || data.address.hamlet || 'Unknown Location';
              setCity(cityName);

              onSetLocation(latitude, longitude, cityName);
              onClose();
            } else {
              alert('Unable to determine city from your current location.');
            }
          } catch (error) {
            console.error('Error fetching city name from coordinates:', error);
            alert('Failed to fetch city name from current location.');
          }
        },
        (error) => {
          console.error('Error fetching current location:', error);

          // Messages d'erreur améliorés selon le code d'erreur
          switch (error.code) {
            case error.PERMISSION_DENIED:
              alert('Permission denied. Please enable location services in your browser settings.');
              break;
            case error.POSITION_UNAVAILABLE:
              alert('Location information is unavailable. Make sure your device has GPS or network access.');
              break;
            case error.TIMEOUT:
              alert('The request to get your location timed out. Please try again.');
              break;
            default:
              alert('An unknown error occurred while fetching your location.');
              break;
          }
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
      );
    } else {
      alert('Geolocation is not supported by your browser.');
    }
  };

  return (
    <div className="geolocation-modal">
      <div className="geolocation-modal-content">
        <h2>Choose Your Location</h2>
        <input
          type="text"
          value={city}
          onChange={(e) => handleCityInput(e.target.value)}
          placeholder="Enter city name"
        />
        {suggestions.length > 0 && (
          <ul className="suggestions-list">
            {suggestions.map((suggestion) => (
              <li key={suggestion.place_id}>
                <button
                  className="suggestion-button"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion.display_name}
                </button>
              </li>
            ))}
          </ul>
        )}
        <div className="geolocation-modal-buttons">
          <button onClick={() => onSetLocation(city)}>Set Location</button>
          <button onClick={handleUseCurrentLocation}>Use Current Location</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default GeolocationModal;