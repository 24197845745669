// src/Pages/HomePage.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css'; // Importer le fichier CSS
import logo from '../Assets/logo meet and travel.png'; // Importer le logo

const HomePage = () => {  // Supprimé `setUser`
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    navigate('/signup-email'); // Redirige directement vers la page d'inscription par email
  };

  const handleLoginEmailClick = () => {
    navigate('/login-email'); // Redirige vers la page de connexion par email
  };

  return (
    <div className="home-page">
      <div className="logo-container">
        <img src={logo} alt="Meet and Travel Logo" className="logo" />
      </div>
      <button onClick={handleLoginEmailClick} className="btn btn-secondary">
        Login with Email
      </button>
      <button onClick={handleRegisterClick} className="btn btn-primary">
        Register
      </button>
    </div>
  );
};

export default HomePage;