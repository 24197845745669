// src/Pages/IdentityVerificationPage.jsx
import React from 'react';
import IdentityVerification from '../components/IdentityVerification';
import ProfilePage from './ProfilePage';
import './IdentityVerificationPage.css';

const IdentityVerificationPage = ({ user }) => {
  return (
    <div className="identity-verification-page">
      <h2>Complete Your Profile and Verify Identity</h2>
      <ProfilePage user={user} />
      <IdentityVerification />
    </div>
  );
};

export default IdentityVerificationPage;