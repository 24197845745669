// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAy6Hh3WoibsmlrMZWuGX1nGiahFEZKb9g",
  authDomain: "applimeet-446eb.firebaseapp.com",
  projectId: "applimeet-446eb",
  storageBucket: "applimeet-446eb.appspot.com",
  messagingSenderId: "1047001622",
  appId: "1:1047001622:web:0f6c33e40fd67d17a71337",
  measurementId: "G-REEK514KTC"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const firestore = getFirestore(app);
const storage = getStorage(app);

export { auth, provider, firestore, storage };
export const db = getFirestore(app);

// Hook personnalisé pour l'authentification
import { useEffect, useState } from 'react';

export function useAuth() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
    });
    return unsubscribe;
  }, []);

  return { currentUser };
}