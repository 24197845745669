import React, { useState, useEffect, useRef } from 'react';
import { collection, addDoc, query, where, onSnapshot, orderBy, Timestamp, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useAuth } from '../firebaseConfig';
import { Link } from 'react-router-dom';
import ReportAbuseModal from './ReportAbuseModal';
import './ChatRoomGroups.css';

function ChatRoomGroups({ chatId, isDisabled, selectedGroup }) {  // Ajoute selectedGroup ici
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState(''); // Ajout de l'état manquant
  const [searchQuery, setSearchQuery] = useState(''); // État pour la recherche
  const [highlightedIndex, setHighlightedIndex] = useState(-1); // Pour naviguer entre les résultats
  const { currentUser } = useAuth();
  const [userNames, setUserNames] = useState({});
  const [isReportModalOpen, setReportModalOpen] = useState(false);
  const [reportedUserId, setReportedUserId] = useState('');
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (!chatId || !currentUser) return;

    const q = query(
      collection(db, 'messages'),
      where('chatId', '==', chatId),
      orderBy('timestamp', 'asc')
    );

    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const msgs = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      const newSenderIds = msgs
        .map(msg => msg.senderId)
        .filter(senderId => !userNames[senderId]);

      if (newSenderIds.length > 0) {
        const newUserNames = await Promise.all(newSenderIds.map(async (senderId) => {
          const userDoc = await getDoc(doc(db, 'users', senderId));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            return { [senderId]: `${userData.profile.firstName} ${userData.profile.lastName.charAt(0)}.` };
          }
          return { [senderId]: 'Unknown User' };
        }));

        setUserNames(prevState => ({
          ...prevState,
          ...Object.assign({}, ...newUserNames)
        }));
      }

      setMessages(msgs);
    });

    return () => unsubscribe();
  }, [chatId, currentUser, userNames]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;

    await addDoc(collection(db, 'messages'), {
      chatId,
      text: newMessage,
      timestamp: Timestamp.fromDate(new Date()),
      senderId: currentUser.uid
    });

    setNewMessage(''); // Réinitialiser le message après l'envoi
  };

  const handleReportClick = (userId = null) => {
    setReportedUserId(userId);
    setReportModalOpen(true);
  };

  const handleCloseReportModal = () => {
    setReportModalOpen(false);
    setReportedUserId('');
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setHighlightedIndex(-1); // Reset la navigation des résultats
  };

  const handleNextResult = () => {
    const searchResults = getSearchResults();
    if (searchResults.length > 0) {
      setHighlightedIndex((prevIndex) => (prevIndex + 1) % searchResults.length);
    }
  };

  const handlePrevResult = () => {
    const searchResults = getSearchResults();
    if (searchResults.length > 0) {
      setHighlightedIndex((prevIndex) => (prevIndex - 1 + searchResults.length) % searchResults.length);
    }
  };

  const getSearchResults = () => {
    return messages
      .filter((msg) => msg.text.toLowerCase().includes(searchQuery.toLowerCase()))
      .map((msg) => msg.id);
  };

  const isHighlighted = (id) => {
    const searchResults = getSearchResults();
    return searchResults[highlightedIndex] === id;
  };

  const highlightText = (text, query) => {
    if (!query) return text;
    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    return parts.map((part, i) =>
      part.toLowerCase() === query.toLowerCase() ? <mark key={i}>{part}</mark> : part
    );
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, highlightedIndex]);

  return (
    <div className={`chat-room ${isDisabled ? 'disabled' : ''}`}>
      <div className="chat-header">
  {/* Barre de recherche et boutons de navigation */}
  <div className="search-controls">
  <input
  type="text"
  value={searchQuery}
  onChange={handleSearchChange}
  placeholder="Search messages..."
  className="search-bar"
  disabled={!selectedGroup}  // Désactiver la barre si aucun groupe n'est sélectionné
/>

<button onClick={handlePrevResult} className="search-navigation" disabled={!searchQuery || !selectedGroup}>
  Previous
</button>
<button onClick={handleNextResult} className="search-navigation" disabled={!searchQuery || !selectedGroup}>
  Next
</button>
  </div>
</div>

      <div className="messages">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`message ${message.senderId === currentUser.uid ? 'sent' : 'received'} ${isHighlighted(message.id) ? 'highlighted' : ''}`}
          >
            <div className="sender-info">
              {message.senderId === currentUser.uid ? (
                'You'
              ) : (
                <Link to={`/user-profile/${message.senderId}`}>
                  {userNames[message.senderId] || 'Other'}
                </Link>
              )}
            </div>
            <div className="message-info">
              {new Date(message.timestamp.toDate()).toLocaleString()}
            </div>
            <div>{highlightText(message.text, searchQuery)}</div>
            {message.senderId !== currentUser.uid && (
              <button className="small-report-button" onClick={() => handleReportClick(message.senderId)}>
                Report
              </button>
            )}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      <form className="chat-input" onSubmit={handleSendMessage}>
        <input
          type="text"
          value={newMessage} // Mise à jour de la valeur de l'input
          onChange={(e) => setNewMessage(e.target.value)} // Mise à jour de l'état
          placeholder="Write your message..."
          disabled={isDisabled}
        />
        <button type="submit" disabled={isDisabled}>
          Send
        </button>
      </form>

      {isReportModalOpen && (
        <ReportAbuseModal
          onClose={handleCloseReportModal}
          reportedUserId={reportedUserId}
          chatId={chatId}
        />
      )}
    </div>
  );
}

export default ChatRoomGroups;