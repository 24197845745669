import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, onSnapshot, collection, updateDoc } from 'firebase/firestore';
import { useAuth } from '../firebaseConfig';
import './MeetingsPage.css';
import { Link } from 'react-router-dom';
import { initiateMeeting } from '../components/initiateMeeting';

function MeetingsPage() {
  const { currentUser } = useAuth();
  const [meetings, setMeetings] = useState([]);
  const [friends, setFriends] = useState([]);
  const [meetPoints, setMeetPoints] = useState(0);

  useEffect(() => {
    if (currentUser) {
      const db = getFirestore();
      const meetingsRef = collection(db, `users/${currentUser.uid}/meetings`);

      const userDocRef = doc(db, 'users', currentUser.uid);
      const removeMeetingNotifications = async () => {
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const notifications = userDoc.data().notifications || [];
          const updatedNotifications = notifications.filter(
            notification => notification.type !== 'meeting_initiated'
          );

          if (notifications.length !== updatedNotifications.length) {
            await updateDoc(userDocRef, {
              notifications: updatedNotifications
            });
          }
        }
      };

      removeMeetingNotifications();

      const unsubscribeMeetings = onSnapshot(meetingsRef, async (snapshot) => {
        const meetingsData = await Promise.all(
          snapshot.docs.map(async (docSnapshot) => {
            const meeting = docSnapshot.data();
            const otherUserId = meeting.senderId === currentUser.uid ? meeting.receiverId : meeting.senderId;
            const otherUserDoc = await getDoc(doc(getFirestore(), 'users', otherUserId));
            let otherUser = { name: 'Unknown User', profilePicture: '' };
            if (otherUserDoc.exists()) {
              const userData = otherUserDoc.data();
              otherUser = {
                name: `${userData.profile.firstName} ${userData.profile.lastName.charAt(0)}.`,
                profilePicture: userData.profile.profilePicture || '',
              };
            }
            return {
              ...meeting,
              otherUser,
              otherUserId,
            };
          })
        );
        setMeetings(meetingsData);
      });

      const friendsRef = doc(db, 'users', currentUser.uid);
      const unsubscribeFriends = onSnapshot(friendsRef, async (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          const friendsData = userData.friends || [];
          const enrichedFriends = await Promise.all(
            friendsData.map(async (friendId) => {
              const friendDoc = await getDoc(doc(db, 'users', friendId));
              if (friendDoc.exists()) {
                const friend = friendDoc.data();
                return {
                  id: friendId,
                  name: `${friend.profile.firstName} ${friend.profile.lastName.charAt(0)}.`,
                  profilePicture: friend.profile.profilePicture || ''
                };
              } else {
                return { id: friendId, name: 'Unknown User', profilePicture: '' };
              }
            })
          );
          setFriends(enrichedFriends);
        }
      });

      const fetchMeetPoints = async () => {
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const points = userData.meetPoints || 0;
          setMeetPoints(points);
        }
      };

      fetchMeetPoints();

      return () => {
        unsubscribeMeetings();
        unsubscribeFriends();
      };
    }
  }, [currentUser]);

  const handleInitiateMeeting = async (friendId) => {
    try {
      const db = getFirestore();
  
      // Récupérer le document de l'utilisateur actuel
      const currentUserRef = doc(db, 'users', currentUser.uid);
      const currentUserDoc = await getDoc(currentUserRef);
      
      if (!currentUserDoc.exists()) {
        throw new Error('User data for current user does not exist.');
      }
      
      const currentUserData = currentUserDoc.data();
      
      // Log les données pour voir si elles sont bien récupérées
      console.log('Current User Data:', currentUserData);
      
      // Récupérer le document de l'ami
      const friendRef = doc(db, 'users', friendId);
      const friendDoc = await getDoc(friendRef);
      
      if (!friendDoc.exists()) {
        throw new Error('User data for the friend does not exist.');
      }
  
      const friendData = friendDoc.data();
      
      // Log les données de l'ami
      console.log('Friend Data:', friendData);
      
      if (!currentUserData.profile || !friendData.profile) {
        throw new Error('Profile data missing for one of the users.');
      }
      
      // Log les noms
      console.log('Current User Name:', currentUserData.profile.firstName, currentUserData.profile.lastName);
      console.log('Friend Name:', friendData.profile.firstName, friendData.profile.lastName);
  
      // Initier la rencontre
      await initiateMeeting(currentUser, friendId);
    
      // Envoi d'un email à l'ami pour l'informer de la demande de rencontre
      await fetch('https://mail.meevel.app/send_email.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          'email': friendData.email, // L'email de l'ami
          'username': `${currentUserData.profile.firstName} ${currentUserData.profile.lastName.charAt(0)}.`, // Nom de l'utilisateur actuel
          'type': 'meeting_request', // Type d'e-mail : demande de rencontre
        }),
      });
  
      alert('Meeting successfully initiated and email sent to the friend!');
    } catch (error) {
      console.error('Error initiating the meeting:', error);
      alert('An error occurred while initiating the meeting.');
    }
  };

  const handleAcceptMeeting = async (meetingId) => {
    try {
      const db = getFirestore();
      const meetingRef = doc(db, `users/${currentUser.uid}/meetings`, meetingId);
      const otherMeetingRef = doc(db, `users/${meetingId.split('_')[0]}/meetings`, meetingId);
  
      // Mettre à jour l'état du meeting pour confirmer la rencontre
      await updateDoc(meetingRef, {
        status: 'confirmed',
        confirmedByReceiver: true,
      });
      await updateDoc(otherMeetingRef, {
        status: 'confirmed',
        confirmedByReceiver: true,
      });
  
      // Fonction pour mettre à jour les MeetPoints
      const updateMeetPoints = async (userDocRef) => {
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const currentPoints = userDoc.data().meetPoints || 0;
          await updateDoc(userDocRef, {
            meetPoints: currentPoints + 1,
          });
        }
      };
  
      // Mise à jour des MeetPoints pour les deux utilisateurs
      await updateMeetPoints(doc(db, 'users', currentUser.uid));
      await updateMeetPoints(doc(db, 'users', meetingId.split('_')[0]));
  
      // Mettre à jour l'état des points Meetpoints et des évaluations en attente
      await updateDoc(meetingRef, {
        meetPointsAwarded: true,
        meetPointsStatus: "awarded",
        ratingPending: true
      });
      await updateDoc(otherMeetingRef, {
        meetPointsAwarded: true,
        meetPointsStatus: "awarded",
        ratingPending: true
      });
  
      alert('Meeting confirmed! Meetpoints awarded to both users.');
  
      // Mettre à jour les MeetPoints en temps réel
      const userDocRef = doc(db, 'users', currentUser.uid);
      const updatedPoints = await getDoc(userDocRef);
      if (updatedPoints.exists()) {
        setMeetPoints(updatedPoints.data().meetPoints || 0);
      }
  
      setMeetings((prevMeetings) =>
        prevMeetings.map((meeting) =>
          meeting.meetingId === meetingId ? { ...meeting, status: 'confirmed' } : meeting
        )
      );
  
      // Récupérer les données des deux utilisateurs (actuel et l'autre utilisateur)
      const otherUserRef = doc(db, 'users', meetingId.split('_')[0]);
      const otherUserDoc = await getDoc(otherUserRef);
      const currentUserProfile = currentUser.profile || {};
  
      if (otherUserDoc.exists()) {
        const otherUserData = otherUserDoc.data();
  
        // Vérification de l'existence de `firstName` et `lastName`
        const currentUserFirstName = currentUserProfile.firstName || 'User';
        const currentUserLastNameInitial = currentUserProfile.lastName ? currentUserProfile.lastName.charAt(0) : '';
        const otherUserFirstName = otherUserData.profile.firstName || 'User';
        const otherUserLastNameInitial = otherUserData.profile.lastName ? otherUserData.profile.lastName.charAt(0) : '';
  
        // Fonction pour envoyer l'e-mail de confirmation de rencontre aux deux utilisateurs
        const sendEmail = async (recipientEmail, recipientName) => {
          await fetch('https://meevel.app/send_email.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
              'email': recipientEmail, // L'e-mail du destinataire
              'username': recipientName, // Le nom de l'utilisateur qui reçoit l'e-mail
              'type': 'meeting_confirmed', // Type d'e-mail : rencontre confirmée
            }),
          });
        };
  
        // Envoi d'un e-mail à l'utilisateur actuel
        await sendEmail(currentUser.email, `${currentUserFirstName} ${currentUserLastNameInitial}.`);
  
        // Envoi d'un e-mail à l'autre utilisateur
        await sendEmail(otherUserData.email, `${otherUserFirstName} ${otherUserLastNameInitial}.`);
      }
    } catch (error) {
      console.error('Error accepting the meeting:', error);
      alert('An error occurred while accepting the meeting.');
    }
  };

  const handleRejectMeeting = async (meetingId) => {
    const db = getFirestore();
    const meetingRef = doc(db, `users/${currentUser.uid}/meetings`, meetingId);
    const otherMeetingRef = doc(db, `users/${meetingId.split('_')[0]}/meetings`, meetingId);

    await updateDoc(meetingRef, {
      status: 'rejected',
    });
    await updateDoc(otherMeetingRef, {
      status: 'rejected',
    });

    setMeetings((prevMeetings) =>
      prevMeetings.map((meeting) =>
        meeting.meetingId === meetingId ? { ...meeting, status: 'rejected' } : meeting
      )
    );
  };

  const getMeetingForFriend = (friendId) => {
    return meetings.find(
      (meeting) =>
        (meeting.senderId === currentUser.uid && meeting.receiverId === friendId) ||
        (meeting.receiverId === currentUser.uid && meeting.senderId === friendId)
    );
  };

  return (
    <div className="meetings-page">
      <h1>Meetings</h1>
      <div className="friends-section">
        <h2>My Friends</h2>
        {friends.length > 0 ? (
          <ul>
            {friends.map((friend, index) => {
              const meeting = getMeetingForFriend(friend.id);
              return (
                <li key={index} className="meeting-item">
                  <Link to={`/user-profile/${friend.id}`}>
                    {friend.profilePicture && (
                      <img src={friend.profilePicture} alt={`${friend.name} profile`} className="profile-picture" />
                    )}
                    {friend.name}
                  </Link>
                  <div className="meeting-actions">
                    {!meeting && (
                      <button
                        className="initiate-button"
                        onClick={() => handleInitiateMeeting(friend.id)}
                      >
                        I have met this friend
                      </button>
                    )}
                    {meeting && meeting.status === 'pending' && meeting.senderId === currentUser.uid && (
                      <button className="action-button" disabled>
                        Meeting Pending Validation
                      </button>
                    )}
                    {meeting && meeting.status === 'pending' && meeting.receiverId === currentUser.uid && (
                      <>
                        <p>{`This user wants to validate the meeting with you: ${friend.name}`}</p>
                        <div className="meeting-actions">
                          <button
                            className="accept-button action-button"
                            onClick={() => handleAcceptMeeting(meeting.meetingId)}
                          >
                            Accept
                          </button>
                          <button
                            className="reject-button action-button"
                            onClick={() => handleRejectMeeting(meeting.meetingId)}
                          >
                            Reject
                          </button>
                        </div>
                      </>
                    )}
                    {meeting && meeting.status === 'confirmed' && (
                      <button className="action-button" disabled>
                        Meeting Confirmed
                      </button>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <p>You don&apos;t have any friends yet.</p>
        )}
      </div>

      {/* Meetpoints Section */}
      <div className="meetpoints-section">
        <h2>My Meetpoints</h2>
        <div className="meetpoints-content">
          <i className="fas fa-star meetpoints-icon"></i> {/* Star icon for Meetpoints */}
          <p>{`You have ${meetPoints} Meetpoints.`}</p> {/* Displays the number of Meetpoints */}
        </div>
      </div>
    </div>
  );
}

export default MeetingsPage;