import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './Map.css';
import { getFirestore, collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../firebaseConfig';
import flecheBack from '../Assets/fleche_back.png';
import flecheNext from '../Assets/fleche_next.png';

// Fonction pour calculer la distance entre deux points
const haversineDistance = (coords1, coords2) => {
  const toRad = (x) => (x * Math.PI) / 180;
  const R = 6371; // Rayon de la Terre en km

  const dLat = toRad(coords2.lat - coords1.lat);
  const dLon = toRad(coords2.lng - coords1.lng);
  const lat1 = toRad(coords1.lat);
  const lat2 = toRad(coords2.lat);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
};

// Fonction pour ajouter un décalage aléatoire jusqu'à 1 km aux coordonnées
const offsetCoordinates = (lat, lng) => {
  const randomOffset = () => (Math.random() - 0.5) * (1 / 110.574); // 1 km ~ 1/110.574° latitude
  return {
    lat: lat + randomOffset(),
    lng: lng + randomOffset() / Math.cos(lat * (Math.PI / 180))
  };
};

const formatLastLogin = (lastLogin) => {
  if (!lastLogin || !lastLogin.seconds) return 'N/A';
  const date = new Date(lastLogin.seconds * 1000);
  return date.toLocaleString();
};

function Map() {
  const { currentUser } = useAuth();
  const [referenceLocation, setReferenceLocation] = useState(null); // Position réelle
  const [nearbyUsers, setNearbyUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const mapRef = useRef();

  // Composant pour écouter les événements de la carte et enregistrer les déplacements
  const SaveMapPositionOnDrag = () => {
    const map = useMapEvents({
      dragend: () => {
        const center = map.getCenter();
        sessionStorage.setItem('mapPosition', JSON.stringify({
          lat: center.lat,
          lng: center.lng
        }));
        console.log('Position enregistrée après déplacement:', center);
        setReferenceLocation({ lat: center.lat, lng: center.lng }); // Met à jour la position de référence
      }
    });
    return null;
  };

  // Récupérer la localisation de l'utilisateur depuis Firestore ou la dernière position enregistrée
  useEffect(() => {
    const savedPosition = sessionStorage.getItem('mapPosition');

    if (savedPosition) {
      const parsedPosition = JSON.parse(savedPosition);
      setReferenceLocation(parsedPosition);
      if (mapRef.current) {
        mapRef.current.setView([parsedPosition.lat, parsedPosition.lng], 13);
      }
    } else {
      const fetchUserLocationFromFirestore = async () => {
        if (currentUser) {
          const db = getFirestore();
          const userDocRef = doc(db, 'users', currentUser.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            // Appliquer un décalage d'un kilomètre pour brouiller la position
            const firestoreLocation = offsetCoordinates(userData.lat || 0, userData.lng || 0);

            setReferenceLocation(firestoreLocation);
            sessionStorage.setItem('mapPosition', JSON.stringify(firestoreLocation));

            if (mapRef.current) {
              mapRef.current.setView([firestoreLocation.lat, firestoreLocation.lng], 13);
            }
          }
        }
      };

      fetchUserLocationFromFirestore();
    }
  }, [currentUser]);

  // Récupérer tous les utilisateurs de Firestore avec un décalage d'1 km
  useEffect(() => {
    const fetchFirestoreUsers = async () => {
      const db = getFirestore();
      const querySnapshot = await getDocs(collection(db, 'users'));
      const firestoreUsers = querySnapshot.docs.map(doc => {
        const userData = doc.data();
        const offsetCoords = offsetCoordinates(userData.lat, userData.lng);
        return {
          id: doc.id,
          ...userData,
          lat: offsetCoords.lat,
          lng: offsetCoords.lng,
          realLat: userData.lat, // Stocke les vraies coordonnées pour les calculs de distance
          realLng: userData.lng
        };
      });
      setUsers(firestoreUsers.filter(user => user.id !== currentUser?.uid));
    };

    fetchFirestoreUsers();
  }, [currentUser]);

  // Mettre à jour les utilisateurs proches lorsque la localisation change
  useEffect(() => {
    if (referenceLocation) {
      const updatedNearbyUsers = users
        .filter(user => user.lat && user.lng)
        .map(user => ({
          ...user,
          distance: haversineDistance(referenceLocation, { lat: user.realLat, lng: user.realLng }), // Calcule la distance depuis la vraie position
        }))
        .sort((a, b) => a.distance - b.distance);
      setNearbyUsers(updatedNearbyUsers);
    }
  }, [referenceLocation, users]);

  // Fonction qui réorganise le carousel en mettant l'utilisateur cliqué en premier
  const handleUserClick = (clickedUser) => {
    if (!referenceLocation) {
      console.error("La position de référence n'est pas définie");
      return;  // On évite une erreur si referenceLocation n'est pas encore disponible
    }

    const clickedUserWithDistance = {
      ...clickedUser,
      distance: haversineDistance(referenceLocation, { lat: clickedUser.realLat, lng: clickedUser.realLng })
    };
    const updatedUsers = [
      clickedUserWithDistance,
      ...nearbyUsers.filter(user => user.id !== clickedUser.id)
    ];
    setNearbyUsers(updatedUsers);
  };

  const profileIcon = (photoUrl) =>
    L.divIcon({
      html: `<div class="user-marker-icon">
               <img src="${photoUrl || 'path_to_default_image.jpg'}" alt="Profile" style="width:50px;height:50px;object-fit:cover;border-radius:50%;" />
             </div>`,
      iconSize: [60, 60],
      className: ''
    });

  return (
    <>
      <div className="map-container">
        <MapContainer
          center={referenceLocation ? [referenceLocation.lat, referenceLocation.lng] : [0, 0]}
          zoom={13}
          className="leaflet-container"
          ref={mapRef}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
          />
          <SaveMapPositionOnDrag /> {/* Composant pour capturer l'événement de drag */}
          {users.map((user, index) => (
            user.lat && user.lng ? (
              <Marker
                key={index}
                position={[user.lat, user.lng]}
                icon={profileIcon(user.profile?.profilePicture)}
                eventHandlers={{
                  click: () => handleUserClick(user), // Met l'utilisateur cliqué en premier dans le carousel
                }}
              />
            ) : null
          ))}
        </MapContainer>
      </div>

      {nearbyUsers.length > 0 && (
        <div className="nearby-users-carousel">
          <Carousel
            controls={true}
            indicators={false}
            interval={null}
            prevIcon={<img src={flecheBack} alt="Previous" style={{ width: '40px', height: '40px' }} />}
            nextIcon={<img src={flecheNext} alt="Next" style={{ width: '40px', height: '40px' }} />}
          >
            {nearbyUsers.map((user, index) => (
              <Carousel.Item key={index}>
                <div className="d-flex justify-content-around w-100">
                  <div className="user-item text-center position-relative">
                    <div className="status-indicator">
                      <span className={`status-dot ${user.onlineStatus === 'online' ? 'online' : 'offline'}`}></span>
                      <span className="status-text">
                        {user.onlineStatus === 'online'
                          ? 'Connected'
                          : `Offline since ${formatLastLogin(user.lastLogin)}`}
                      </span>
                    </div>
                    <Link to={`/user-profile/${user.id}`} className="profile-container">
                      <img
                        src={user.profile?.profilePicture || 'path_to_default_image.jpg'}
                        alt="Profile"
                        className="user-profile-image"
                      />
                      <h5>
                        {user.profile?.firstName ?? 'N/A'}{" "}
                        {user.profile?.lastName ? user.profile.lastName.charAt(0) : ''}.
                      </h5>
                    </Link>
                    {user.distance && (
                      <p>{user.distance.toFixed(1)} km from your position</p>
                    )}
                    <p>Age: {user.profile?.age ?? 'N/A'}</p>
                    <p>Nationality: {user.profile?.nationality ?? 'N/A'}</p>
                    <p>Bio: {user.profile?.bio ?? 'N/A'}</p>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      )}
    </>
  );
}

export default Map;