// src/Pages/PrivacyPolicy.js
import React from 'react';
import './PrivacyPolicy.css'; // Import du CSS

function PrivacyPolicy() {
  return (
    <div className="privacy-policy-container">
      <p>
        <h3>1. Introduction</h3>
        This privacy policy explains how Meevel SAS (&quot;we,&quot; &quot;our,&quot; or &quot;Meevel&quot;) collects, uses, shares, and protects personal information you provide when using our mobile application and website. It applies to users worldwide, including those in Asia, where we are initially focusing our development.
      </p>
      <p>
        <h3>2. Information Collected</h3>
        We collect various types of information to provide you with our services:
      </p>
      <ul>
        <li>
          <em>Information You Provide Directly:</em> When registering or using our services, we collect personal information such as your name, email address, date of birth, profile photo, travel preferences, messages exchanged with other users, and any other information you choose to share.
        </li>
        <li>
          <em>Geolocation Information:</em> For the proper functioning of our travel-friendly matchmaking application, we collect and process your real-time geolocation data. This information is essential to connect you with other users nearby and provide you with personalized travel recommendations.
        </li>
        <li>
          <em>Information Collected Automatically:</em> We automatically collect information when you use our app or website, including your IP address, device type, operating system, browsing history, location data, and information about your interaction with our services.
        </li>
        <li>
          <em>Information from Third Parties:</em> We may receive information about you from business partners, social networking services (if you use your social media credentials to log in), or other third-party sources.
        </li>
      </ul>
      <p>
        <h3>3. Use of Information</h3>
        We use your information for the following purposes:
      </p>
      <ul>
        <li>To provide, operate, maintain, and improve our services.</li>
        <li>To facilitate connections between users based on your travel preferences and geolocation data.</li>
        <li>To display certain user information, such as your first name, age, and travel preferences, in the public sections of the app.</li>
        <li>To personalize your user experience and display targeted advertisements based on your preferences, location, and use of the app.</li>
        <li>To offer you paid premium subscriptions and other paid services.</li>
        <li>To contact you about updates, new features, promotions, and other information related to our app and services.</li>
        <li>To prevent and detect any fraudulent activity, abuse, unauthorized access, and other illegal activities.</li>
      </ul>
      <p>
        <h3>4. Sharing of Information</h3>
        We may share your information with:
      </p>
      <ul>
        <li>
          <em>Our Partners and Service Providers:</em> Those who help us operate our services, such as data storage providers, payment service providers, advertising platforms, and analytics services.
        </li>
        <li>
          <em>Other Users:</em> Information you choose to make public on your profile will be visible to other app users. This information includes your first name, age, profile photo, travel preferences, and any other data you choose to share.
        </li>
        <li>
          <em>Legal Authorities:</em> In response to a legal request, or when necessary to comply with the law, protect our rights, security, or that of others.
        </li>
        <li>
          <em>Advertising Partners:</em> We may share certain anonymous or aggregated information with our advertising partners to improve the relevance of the ads you see.
        </li>
      </ul>
      <p>
        <h3>5. Information Security</h3>
        We implement technical, administrative, and physical security measures to protect your personal information against unauthorized access, disclosure, alteration, or destruction. However, no method of electronic transmission or storage is completely secure, and we cannot guarantee absolute security.
      </p>
      <p>
        <h3>6. Your Rights</h3>
        In accordance with the General Data Protection Regulation (GDPR), the California Consumer Privacy Act (CCPA), and other applicable local laws, you have the following rights:
      </p>
      <ul>
        <li>Right of Access: You can request a copy of the personal information we hold about you.</li>
        <li>Right to Rectification: You can request the correction of any inaccurate or incomplete information.</li>
        <li>Right to Erasure: You can request the deletion of your personal information, subject to legal retention requirements.</li>
        <li>Right to Object: You can object to the use of your personal data for direct marketing or profiling purposes.</li>
        <li>Right to Data Portability: You have the right to receive your personal data in a structured, machine-readable format.</li>
      </ul>
      <p>
        <h3>7. Data Retention</h3>
        We retain your personal information for as long as necessary to fulfill the purposes described in this policy, unless a longer retention period is required or permitted by law. If you delete your account, we will delete your personal information within a reasonable timeframe, unless a legal obligation requires us to retain it.
      </p>
      <p>
        <h3>8. International Data Transfers</h3>
        Your personal information may be transferred to and processed in countries other than the one you reside in, including countries outside the European Union. We ensure that appropriate safeguards are in place to protect your personal data during such transfers, in compliance with applicable data protection laws.
      </p>
      <p>
        <h3>9. Use of Cookies and Similar Technologies</h3>
        We use cookies and other similar technologies to collect information about your use of our app and website, to improve our services, and to personalize the content and advertisements we provide to you. You can manage your cookie preferences in your browser settings.
      </p>
      <p>
        <h3>10. Advertising and Premium Offers</h3>
        Meevel displays personalized advertisements on its app. By using our app, you agree to receive targeted ads based on your travel preferences and location. We also offer paid premium subscriptions that provide additional features, such as an ad-free experience and access to exclusive services.
      </p>
      <p>
        <h3>11. Mandatory Geolocation</h3>
        To use our travel-friendly matchmaking application, you must enable real-time geolocation. This feature is mandatory to benefit from our services, which rely on your location to connect you with other users nearby. By using our app, you agree that your location will be tracked and shared in accordance with this privacy policy.
      </p>
      <p>
        <h3>12. Regional Compliance</h3>
        Meevel complies with the specific local regulations of the countries where we operate. In Asia, particularly in Indonesia, we respect local data protection laws and are committed to protecting your personal information in accordance with international best practices.
      </p>
      <p>
        <h3>13. Changes to the Privacy Policy</h3>
        We reserve the right to modify this privacy policy at any time. We will notify you of any significant changes through our app or website.
      </p>
      <p>
        <h3>14. Contact Us</h3>
        If you have any questions or requests regarding this privacy policy or the processing of your personal data, please contact us at: support@meevel.app.
      </p>
      <p>
        <h3>15. Trademark Notice</h3>
        &quot;Meevel&quot; is a registered trademark with the INPI (French National Institute of Industrial Property).
      </p>
    </div>
  );
}

export default PrivacyPolicy;