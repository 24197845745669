// src/components/Tutorial.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Joyride from 'react-joyride';
import './Tutorial.css';

const Tutorial = () => {
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);
  const navigate = useNavigate();

  // Définir les étapes du tutoriel en fonction de la page actuelle
  const getStepsForCurrentPage = () => {
    const pathname = window.location.pathname;

    if (pathname === '/user-map') {
      return [
        {
          target: '.leaflet-container',
          content: 'Voici la carte. Utilisez-la pour explorer les utilisateurs autour de votre position.',
          placement: 'top',
          disableBeacon: true,
        },
      ];
    } else if (pathname === '/countrycitylist') {
      return [
        {
          target: '.nearby-users-section',
          content: 'Découvrez les utilisateurs à proximité pour vous connecter.',
        },
        {
          target: '.groups-section.groups-nearby',
          content: 'Rejoignez des groupes à proximité pour des discussions et rencontres locales.',
        },
        {
          target: '.advertisements-section',
          content: 'Découvrez les annonces locales disponibles dans votre région.',
        },
        {
          target: '#country',
          content: 'Sélectionnez votre pays pour personnaliser votre expérience.',
          placement: 'bottom-start',
          disableBeacon: true,
        },
        {
          target: '#city',
          content: 'Choisissez une ville pour découvrir les groupes et annonces spécifiques à cet endroit.',
          placement: 'bottom-start',
          disableBeacon: true,
        },
        {
          target: '#group',
          content: 'Sélectionnez un groupe de discussion pour rejoindre des conversations locales.',
          placement: 'bottom-start',
          disableBeacon: true,
        },
        {
          target: 'button[aria-label*="Add to favorites"]',
          content: 'Ajoutez ce groupe à vos favoris pour y accéder rapidement à tout moment.',
          placement: 'bottom-start',
          disableBeacon: true,
        },
        {
          target: 'body',
          content: 'Passons maintenant à la carte pour explorer les utilisateurs autour de votre position.',
          placement: 'center',
          disableBeacon: true,
        },
      ];
    } else {
      return []; // Aucune étape définie pour les autres pages
    }
  };

  useEffect(() => {
    const savedStepIndex = parseInt(localStorage.getItem('tutorialStepIndex'), 10);
    const shouldRun = localStorage.getItem('startTutorial') === 'true';

    if (shouldRun) {
      setRun(true);
      setStepIndex(savedStepIndex || 0);
      setSteps(getStepsForCurrentPage());
    }
  }, []);

  useEffect(() => {
    const target = steps[stepIndex]?.target;
    if (target && !document.querySelector(target)) {
      console.log(`Waiting for target ${target} to mount...`);
      const interval = setInterval(() => {
        if (document.querySelector(target)) {
          console.log(`Target ${target} mounted.`);
          clearInterval(interval);
        }
      }, 100);

      return () => clearInterval(interval);
    }
  }, [stepIndex, steps]);

  const handleJoyrideCallback = (data) => {
    const { status, action, lifecycle, index } = data;
    const finishedStatuses = ['finished', 'skipped'];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      localStorage.removeItem('tutorialStepIndex');
      localStorage.removeItem('startTutorial');
    } else if (action === 'next' && lifecycle === 'complete') {
      if (index === 2) {
        localStorage.setItem('tutorialStepIndex', 3);
        navigate('/countrycitylist');
      } else if (index === 7) {
        localStorage.setItem('tutorialStepIndex', 8);
        navigate('/user-map');
        setSteps(getStepsForCurrentPage());
      } else {
        setStepIndex((prevIndex) => prevIndex + 1);
        localStorage.setItem('tutorialStepIndex', index + 1);
      }
    } else if (action === 'prev' && lifecycle === 'complete') {
      setStepIndex((prevIndex) => Math.max(prevIndex - 1, 0));
      localStorage.setItem('tutorialStepIndex', Math.max(index - 1, 0));
    }
  };

  const handleStopTutorial = () => {
    setRun(false);
    setStepIndex(0);
    localStorage.removeItem('tutorialStepIndex');
    localStorage.removeItem('startTutorial');
  };

  return (
    <div>
      <Joyride
        steps={steps}
        continuous={true}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        run={run}
        stepIndex={stepIndex}
        callback={handleJoyrideCallback}
        disableScrolling={false}
        spotlightClicks={true}
        scrollOffset={200}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      {run && (
        <button
          onClick={handleStopTutorial}
          className="btn-stop"
          style={{
            position: 'fixed',
            bottom: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 10001,
          }}
        >
          Stopper le didacticiel
        </button>
      )}
    </div>
  );
};

export default Tutorial;