// src/components/ProfileForm.js
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { getFirestore, doc, setDoc, getDoc, updateDoc } from 'firebase/firestore'; // Importer updateDoc
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import './ProfileForm.css';

const languages = [
  { value: 'English', label: 'English' },
  { value: 'French', label: 'French' },
  { value: 'Spanish', label: 'Spanish' },
  { value: 'German', label: 'German' },
  { value: 'Chinese', label: 'Chinese' },
  { value: 'Japanese', label: 'Japanese' },
  { value: 'Russian', label: 'Russian' },
  { value: 'Portuguese', label: 'Portuguese' },
  { value: 'Italian', label: 'Italian' },
  { value: 'Arabic', label: 'Arabic' },
  { value: 'Korean', label: 'Korean' },
  { value: 'Hindi', label: 'Hindi' },
  { value: 'Bengali', label: 'Bengali' },
  { value: 'Turkish', label: 'Turkish' },
  { value: 'Dutch', label: 'Dutch' },
  { value: 'Polish', label: 'Polish' },
  { value: 'Ukrainian', label: 'Ukrainian' },
  { value: 'Swedish', label: 'Swedish' },
  { value: 'Norwegian', label: 'Norwegian' },
  { value: 'Danish', label: 'Danish' },
  { value: 'Finnish', label: 'Finnish' },
  { value: 'Greek', label: 'Greek' },
  { value: 'Hebrew', label: 'Hebrew' },
  { value: 'Hungarian', label: 'Hungarian' },
  { value: 'Czech', label: 'Czech' },
  { value: 'Slovak', label: 'Slovak' },
  { value: 'Thai', label: 'Thai' },
  { value: 'Vietnamese', label: 'Vietnamese' },
  { value: 'Persian', label: 'Persian' },
  { value: 'Urdu', label: 'Urdu' },
  { value: 'Punjabi', label: 'Punjabi' },
  { value: 'Gujarati', label: 'Gujarati' },
  { value: 'Telugu', label: 'Telugu' },
  { value: 'Tamil', label: 'Tamil' },
  { value: 'Malayalam', label: 'Malayalam' },
  { value: 'Marathi', label: 'Marathi' },
  { value: 'Kannada', label: 'Kannada' },
  { value: 'Romanian', label: 'Romanian' },
  { value: 'Bulgarian', label: 'Bulgarian' },
  { value: 'Croatian', label: 'Croatian' },
  { value: 'Serbian', label: 'Serbian' },
  { value: 'Slovenian', label: 'Slovenian' },
  { value: 'Lithuanian', label: 'Lithuanian' },
  { value: 'Latvian', label: 'Latvian' },
  { value: 'Estonian', label: 'Estonian' },
  { value: 'Filipino', label: 'Filipino' },
  { value: 'Malay', label: 'Malay' },
  { value: 'Indonesian', label: 'Indonesian' },
  { value: 'Swahili', label: 'Swahili' },
  { value: 'Amharic', label: 'Amharic' },
  { value: 'Zulu', label: 'Zulu' },
  { value: 'Afrikaans', label: 'Afrikaans' },
  { value: 'Maori', label: 'Maori' },
  { value: 'Samoan', label: 'Samoan' },
  { value: 'Haitian Creole', label: 'Haitian Creole' },
  { value: 'Farsi', label: 'Farsi' },
  { value: 'Basque', label: 'Basque' },
  { value: 'Catalan', label: 'Catalan' },
  { value: 'Galician', label: 'Galician' },
  { value: 'Irish', label: 'Irish' },
  { value: 'Welsh', label: 'Welsh' },
  { value: 'Icelandic', label: 'Icelandic' },
  { value: 'Maltese', label: 'Maltese' },
  { value: 'Armenian', label: 'Armenian' },
  { value: 'Georgian', label: 'Georgian' },
  { value: 'Khmer', label: 'Khmer' },
  { value: 'Lao', label: 'Lao' },
  { value: 'Mongolian', label: 'Mongolian' },
  { value: 'Pashto', label: 'Pashto' },
  { value: 'Sinhalese', label: 'Sinhalese' },
  { value: 'Tajik', label: 'Tajik' },
  { value: 'Uzbek', label: 'Uzbek' },
  { value: 'Kazakh', label: 'Kazakh' },
  { value: 'Kurdish', label: 'Kurdish' },
];

const countries = [
  { value: 'Afghanistan', label: 'Afghanistan' },
  { value: 'Albania', label: 'Albania' },
  { value: 'Algeria', label: 'Algeria' },
  { value: 'Andorra', label: 'Andorra' },
  { value: 'Angola', label: 'Angola' },
  { value: 'Argentina', label: 'Argentina' },
  { value: 'Armenia', label: 'Armenia' },
  { value: 'Australia', label: 'Australia' },
  { value: 'Austria', label: 'Austria' },
  { value: 'Azerbaijan', label: 'Azerbaijan' },
  { value: 'Bahamas', label: 'Bahamas' },
  { value: 'Bahrain', label: 'Bahrain' },
  { value: 'Bangladesh', label: 'Bangladesh' },
  { value: 'Barbados', label: 'Barbados' },
  { value: 'Belarus', label: 'Belarus' },
  { value: 'Belgium', label: 'Belgium' },
  { value: 'Belize', label: 'Belize' },
  { value: 'Benin', label: 'Benin' },
  { value: 'Bhutan', label: 'Bhutan' },
  { value: 'Bolivia', label: 'Bolivia' },
  { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
  { value: 'Botswana', label: 'Botswana' },
  { value: 'Brazil', label: 'Brazil' },
  { value: 'Brunei', label: 'Brunei' },
  { value: 'Bulgaria', label: 'Bulgaria' },
  { value: 'Burkina Faso', label: 'Burkina Faso' },
  { value: 'Burundi', label: 'Burundi' },
  { value: 'Cambodia', label: 'Cambodia' },
  { value: 'Cameroon', label: 'Cameroon' },
  { value: 'Canada', label: 'Canada' },
  { value: 'Cape Verde', label: 'Cape Verde' },
  { value: 'Central African Republic', label: 'Central African Republic' },
  { value: 'Chad', label: 'Chad' },
  { value: 'Chile', label: 'Chile' },
  { value: 'China', label: 'China' },
  { value: 'Colombia', label: 'Colombia' },
  { value: 'Comoros', label: 'Comoros' },
  { value: 'Costa Rica', label: 'Costa Rica' },
  { value: 'Croatia', label: 'Croatia' },
  { value: 'Cuba', label: 'Cuba' },
  { value: 'Cyprus', label: 'Cyprus' },
  { value: 'Czech Republic', label: 'Czech Republic' },
  { value: 'Democratic Republic of the Congo', label: 'Democratic Republic of the Congo' },
  { value: 'Denmark', label: 'Denmark' },
  { value: 'Djibouti', label: 'Djibouti' },
  { value: 'Dominica', label: 'Dominica' },
  { value: 'Dominican Republic', label: 'Dominican Republic' },
  { value: 'Ecuador', label: 'Ecuador' },
  { value: 'Egypt', label: 'Egypt' },
  { value: 'El Salvador', label: 'El Salvador' },
  { value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
  { value: 'Eritrea', label: 'Eritrea' },
  { value: 'Estonia', label: 'Estonia' },
  { value: 'Eswatini', label: 'Eswatini' },
  { value: 'Ethiopia', label: 'Ethiopia' },
  { value: 'Fiji', label: 'Fiji' },
  { value: 'Finland', label: 'Finland' },
  { value: 'France', label: 'France' },
  { value: 'Gabon', label: 'Gabon' },
  { value: 'Gambia', label: 'Gambia' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Germany', label: 'Germany' },
  { value: 'Ghana', label: 'Ghana' },
  { value: 'Greece', label: 'Greece' },
  { value: 'Grenada', label: 'Grenada' },
  { value: 'Guatemala', label: 'Guatemala' },
  { value: 'Guinea', label: 'Guinea' },
  { value: 'Guinea-Bissau', label: 'Guinea-Bissau' },
  { value: 'Guyana', label: 'Guyana' },
  { value: 'Haiti', label: 'Haiti' },
  { value: 'Honduras', label: 'Honduras' },
  { value: 'Hungary', label: 'Hungary' },
  { value: 'Iceland', label: 'Iceland' },
  { value: 'India', label: 'India' },
  { value: 'Indonesia', label: 'Indonesia' },
  { value: 'Iran', label: 'Iran' },
  { value: 'Iraq', label: 'Iraq' },
  { value: 'Ireland', label: 'Ireland' },
  { value: 'Israel', label: 'Israel' },
  { value: 'Italy', label: 'Italy' },
  { value: 'Ivory Coast', label: 'Ivory Coast' },
  { value: 'Jamaica', label: 'Jamaica' },
  { value: 'Japan', label: 'Japan' },
  { value: 'Jordan', label: 'Jordan' },
  { value: 'Kazakhstan', label: 'Kazakhstan' },
  { value: 'Kenya', label: 'Kenya' },
  { value: 'Kiribati', label: 'Kiribati' },
  { value: 'Kuwait', label: 'Kuwait' },
  { value: 'Kyrgyzstan', label: 'Kyrgyzstan' },
  { value: 'Laos', label: 'Laos' },
  { value: 'Latvia', label: 'Latvia' },
  { value: 'Lebanon', label: 'Lebanon' },
  { value: 'Lesotho', label: 'Lesotho' },
  { value: 'Liberia', label: 'Liberia' },
  { value: 'Libya', label: 'Libya' },
  { value: 'Liechtenstein', label: 'Liechtenstein' },
  { value: 'Lithuania', label: 'Lithuania' },
  { value: 'Luxembourg', label: 'Luxembourg' },
  { value: 'Madagascar', label: 'Madagascar' },
  { value: 'Malawi', label: 'Malawi' },
  { value: 'Malaysia', label: 'Malaysia' },
  { value: 'Maldives', label: 'Maldives' },
  { value: 'Mali', label: 'Mali' },
  { value: 'Malta', label: 'Malta' },
  { value: 'Marshall Islands', label: 'Marshall Islands' },
  { value: 'Mauritania', label: 'Mauritania' },
  { value: 'Mauritius', label: 'Mauritius' },
  { value: 'Mexico', label: 'Mexico' },
  { value: 'Micronesia', label: 'Micronesia' },
  { value: 'Moldova', label: 'Moldova' },
  { value: 'Monaco', label: 'Monaco' },
  { value: 'Mongolia', label: 'Mongolia' },
  { value: 'Montenegro', label: 'Montenegro' },
  { value: 'Morocco', label: 'Morocco' },
  { value: 'Mozambique', label: 'Mozambique' },
  { value: 'Myanmar', label: 'Myanmar' },
  { value: 'Namibia', label: 'Namibia' },
  { value: 'Nauru', label: 'Nauru' },
  { value: 'Nepal', label: 'Nepal' },
  { value: 'Netherlands', label: 'Netherlands' },
  { value: 'New Zealand', label: 'New Zealand' },
  { value: 'Nicaragua', label: 'Nicaragua' },
  { value: 'Niger', label: 'Niger' },
  { value: 'Nigeria', label: 'Nigeria' },
  { value: 'North Korea', label: 'North Korea' },
  { value: 'North Macedonia', label: 'North Macedonia' },
  { value: 'Norway', label: 'Norway' },
  { value: 'Oman', label: 'Oman' },
  { value: 'Pakistan', label: 'Pakistan' },
  { value: 'Palau', label: 'Palau' },
  { value: 'Palestine', label: 'Palestine' },
  { value: 'Panama', label: 'Panama' },
  { value: 'Papua New Guinea', label: 'Papua New Guinea' },
  { value: 'Paraguay', label: 'Paraguay' },
  { value: 'Peru', label: 'Peru' },
  { value: 'Philippines', label: 'Philippines' },
  { value: 'Poland', label: 'Poland' },
  { value: 'Portugal', label: 'Portugal' },
  { value: 'Qatar', label: 'Qatar' },
  { value: 'Republic of the Congo', label: 'Republic of the Congo' },
  { value: 'Romania', label: 'Romania' },
  { value: 'Russia', label: 'Russia' },
  { value: 'Rwanda', label: 'Rwanda' },
  { value: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
  { value: 'Saint Lucia', label: 'Saint Lucia' },
  { value: 'Saint Vincent and the Grenadines', label: 'Saint Vincent and the Grenadines' },
  { value: 'Samoa', label: 'Samoa' },
  { value: 'San Marino', label: 'San Marino' },
  { value: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },
  { value: 'Saudi Arabia', label: 'Saudi Arabia' },
  { value: 'Senegal', label: 'Senegal' },
  { value: 'Serbia', label: 'Serbia' },
  { value: 'Seychelles', label: 'Seychelles' },
  { value: 'Sierra Leone', label: 'Sierra Leone' },
  { value: 'Singapore', label: 'Singapore' },
  { value: 'Slovakia', label: 'Slovakia' },
  { value: 'Slovenia', label: 'Slovenia' },
  { value: 'Solomon Islands', label: 'Solomon Islands' },
  { value: 'Somalia', label: 'Somalia' },
  { value: 'South Africa', label: 'South Africa' },
  { value: 'South Korea', label: 'South Korea' },
  { value: 'South Sudan', label: 'South Sudan' },
  { value: 'Sri Lanka', label: 'Sri Lanka' },
  { value: 'Sudan', label: 'Sudan' },
  { value: 'Suriname', label: 'Suriname' },
  { value: 'Sweden', label: 'Sweden' },
  { value: 'Switzerland', label: 'Switzerland' },
  { value: 'Syria', label: 'Syria' },
  { value: 'Taiwan', label: 'Taiwan' },
  { value: 'Tajikistan', label: 'Tajikistan' },
  { value: 'Tanzania', label: 'Tanzania' },
  { value: 'Thailand', label: 'Thailand' },
  { value: 'Timor-Leste', label: 'Timor-Leste' },
  { value: 'Togo', label: 'Togo' },
  { value: 'Tonga', label: 'Tonga' },
  { value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
  { value: 'Tunisia', label: 'Tunisia' },
  { value: 'Turkey', label: 'Turkey' },
  { value: 'Turkmenistan', label: 'Turkmenistan' },
  { value: 'Tuvalu', label: 'Tuvalu' },
  { value: 'Uganda', label: 'Uganda' },
  { value: 'Ukraine', label: 'Ukraine' },
  { value: 'United Arab Emirates', label: 'United Arab Emirates' },
  { value: 'United Kingdom', label: 'United Kingdom' },
  { value: 'United States', label: 'United States' },
  { value: 'Uruguay', label: 'Uruguay' },
  { value: 'Uzbekistan', label: 'Uzbekistan' },
  { value: 'Vanuatu', label: 'Vanuatu' },
  { value: 'Vatican City', label: 'Vatican City' },
  { value: 'Venezuela', label: 'Venezuela' },
  { value: 'Vietnam', label: 'Vietnam' },
  { value: 'Yemen', label: 'Yemen' },
  { value: 'Zambia', label: 'Zambia' },
  { value: 'Zimbabwe', label: 'Zimbabwe' },
];

const nationalities = [
  { value: 'Afghan', label: 'Afghan' },
  { value: 'Albanian', label: 'Albanian' },
  { value: 'Algerian', label: 'Algerian' },
  { value: 'American', label: 'American' },
  { value: 'Andorran', label: 'Andorran' },
  { value: 'Angolan', label: 'Angolan' },
  { value: 'Argentinian', label: 'Argentinian' },
  { value: 'Armenian', label: 'Armenian' },
  { value: 'Australian', label: 'Australian' },
  { value: 'Austrian', label: 'Austrian' },
  { value: 'Azerbaijani', label: 'Azerbaijani' },
  { value: 'Bahamian', label: 'Bahamian' },
  { value: 'Bahraini', label: 'Bahraini' },
  { value: 'Bangladeshi', label: 'Bangladeshi' },
  { value: 'Barbadian', label: 'Barbadian' },
  { value: 'Belarusian', label: 'Belarusian' },
  { value: 'Belgian', label: 'Belgian' },
  { value: 'Belizean', label: 'Belizean' },
  { value: 'Beninese', label: 'Beninese' },
  { value: 'Bhutanese', label: 'Bhutanese' },
  { value: 'Bolivian', label: 'Bolivian' },
  { value: 'Bosnian', label: 'Bosnian' },
  { value: 'Botswanan', label: 'Botswanan' },
  { value: 'Brazilian', label: 'Brazilian' },
  { value: 'British', label: 'British' },
  { value: 'Bruneian', label: 'Bruneian' },
  { value: 'Bulgarian', label: 'Bulgarian' },
  { value: 'Burkinabe', label: 'Burkinabe' },
  { value: 'Burmese', label: 'Burmese' },
  { value: 'Burundian', label: 'Burundian' },
  { value: 'Cambodian', label: 'Cambodian' },
  { value: 'Cameroonian', label: 'Cameroonian' },
  { value: 'Canadian', label: 'Canadian' },
  { value: 'Cape Verdean', label: 'Cape Verdean' },
  { value: 'Central African', label: 'Central African' },
  { value: 'Chadian', label: 'Chadian' },
  { value: 'Chilean', label: 'Chilean' },
  { value: 'Chinese', label: 'Chinese' },
  { value: 'Colombian', label: 'Colombian' },
  { value: 'Comoran', label: 'Comoran' },
  { value: 'Congolese (Congo-Brazzaville)', label: 'Congolese (Congo-Brazzaville)' },
  { value: 'Congolese (Congo-Kinshasa)', label: 'Congolese (Congo-Kinshasa)' },
  { value: 'Costa Rican', label: 'Costa Rican' },
  { value: 'Croatian', label: 'Croatian' },
  { value: 'Cuban', label: 'Cuban' },
  { value: 'Cypriot', label: 'Cypriot' },
  { value: 'Czech', label: 'Czech' },
  { value: 'Danish', label: 'Danish' },
  { value: 'Djiboutian', label: 'Djiboutian' },
  { value: 'Dominican', label: 'Dominican' },
  { value: 'Dominican (Dominican Republic)', label: 'Dominican (Dominican Republic)' },
  { value: 'Dutch', label: 'Dutch' },
  { value: 'East Timorese', label: 'East Timorese' },
  { value: 'Ecuadorean', label: 'Ecuadorean' },
  { value: 'Egyptian', label: 'Egyptian' },
  { value: 'Emirati', label: 'Emirati' },
  { value: 'English', label: 'English' },
  { value: 'Equatorial Guinean', label: 'Equatorial Guinean' },
  { value: 'Eritrean', label: 'Eritrean' },
  { value: 'Estonian', label: 'Estonian' },
  { value: 'Ethiopian', label: 'Ethiopian' },
  { value: 'Fijian', label: 'Fijian' },
  { value: 'Finnish', label: 'Finnish' },
  { value: 'French', label: 'French' },
  { value: 'Gabonese', label: 'Gabonese' },
  { value: 'Gambian', label: 'Gambian' },
  { value: 'Georgian', label: 'Georgian' },
  { value: 'German', label: 'German' },
  { value: 'Ghanaian', label: 'Ghanaian' },
  { value: 'Greek', label: 'Greek' },
  { value: 'Grenadian', label: 'Grenadian' },
  { value: 'Guatemalan', label: 'Guatemalan' },
  { value: 'Guinean', label: 'Guinean' },
  { value: 'Guinea-Bissauan', label: 'Guinea-Bissauan' },
  { value: 'Guyanese', label: 'Guyanese' },
  { value: 'Haitian', label: 'Haitian' },
  { value: 'Honduran', label: 'Honduran' },
  { value: 'Hungarian', label: 'Hungarian' },
  { value: 'Icelandic', label: 'Icelandic' },
  { value: 'Indian', label: 'Indian' },
  { value: 'Indonesian', label: 'Indonesian' },
  { value: 'Iranian', label: 'Iranian' },
  { value: 'Iraqi', label: 'Iraqi' },
  { value: 'Irish', label: 'Irish' },
  { value: 'Israeli', label: 'Israeli' },
  { value: 'Italian', label: 'Italian' },
  { value: 'Ivorian', label: 'Ivorian' },
  { value: 'Jamaican', label: 'Jamaican' },
  { value: 'Japanese', label: 'Japanese' },
  { value: 'Jordanian', label: 'Jordanian' },
  { value: 'Kazakh', label: 'Kazakh' },
  { value: 'Kenyan', label: 'Kenyan' },
  { value: 'Kiribati', label: 'Kiribati' },
  { value: 'Kuwaiti', label: 'Kuwaiti' },
  { value: 'Kyrgyzstani', label: 'Kyrgyzstani' },
  { value: 'Lao', label: 'Lao' },
  { value: 'Latvian', label: 'Latvian' },
  { value: 'Lebanese', label: 'Lebanese' },
  { value: 'Lesotho', label: 'Lesotho' },
  { value: 'Liberian', label: 'Liberian' },
  { value: 'Libyan', label: 'Libyan' },
  { value: 'Liechtenstein', label: 'Liechtenstein' },
  { value: 'Lithuanian', label: 'Lithuanian' },
  { value: 'Luxembourgish', label: 'Luxembourgish' },
  { value: 'Macedonian', label: 'Macedonian' },
  { value: 'Malagasy', label: 'Malagasy' },
  { value: 'Malawian', label: 'Malawian' },
  { value: 'Malaysian', label: 'Malaysian' },
  { value: 'Maldivian', label: 'Maldivian' },
  { value: 'Malian', label: 'Malian' },
  { value: 'Maltese', label: 'Maltese' },
  { value: 'Marshallese', label: 'Marshallese' },
  { value: 'Mauritanian', label: 'Mauritanian' },
  { value: 'Mauritian', label: 'Mauritian' },
  { value: 'Mexican', label: 'Mexican' },
  { value: 'Micronesian', label: 'Micronesian' },
  { value: 'Moldovan', label: 'Moldovan' },
  { value: 'Monacan', label: 'Monacan' },
  { value: 'Mongolian', label: 'Mongolian' },
  { value: 'Montenegrin', label: 'Montenegrin' },
  { value: 'Moroccan', label: 'Moroccan' },
  { value: 'Mozambican', label: 'Mozambican' },
  { value: 'Namibian', label: 'Namibian' },
  { value: 'Nauruan', label: 'Nauruan' },
  { value: 'Nepalese', label: 'Nepalese' },
  { value: 'New Zealander', label: 'New Zealander' },
  { value: 'Nicaraguan', label: 'Nicaraguan' },
  { value: 'Nigerian', label: 'Nigerian' },
  { value: 'Nigerien', label: 'Nigerien' },
  { value: 'North Korean', label: 'North Korean' },
  { value: 'Norwegian', label: 'Norwegian' },
  { value: 'Omani', label: 'Omani' },
  { value: 'Pakistani', label: 'Pakistani' },
  { value: 'Palauan', label: 'Palauan' },
  { value: 'Palestinian', label: 'Palestinian' },
  { value: 'Panamanian', label: 'Panamanian' },
  { value: 'Papua New Guinean', label: 'Papua New Guinean' },
  { value: 'Paraguayan', label: 'Paraguayan' },
  { value: 'Peruvian', label: 'Peruvian' },
  { value: 'Philippine', label: 'Philippine' },
  { value: 'Polish', label: 'Polish' },
  { value: 'Portuguese', label: 'Portuguese' },
  { value: 'Qatari', label: 'Qatari' },
  { value: 'Romanian', label: 'Romanian' },
  { value: 'Russian', label: 'Russian' },
  { value: 'Rwandan', label: 'Rwandan' },
  { value: 'Saint Lucian', label: 'Saint Lucian' },
  { value: 'Salvadoran', label: 'Salvadoran' },
  { value: 'Samoan', label: 'Samoan' },
  { value: 'San Marinese', label: 'San Marinese' },
  { value: 'Sao Tomean', label: 'Sao Tomean' },
  { value: 'Saudi', label: 'Saudi' },
  { value: 'Scottish', label: 'Scottish' },
  { value: 'Senegalese', label: 'Senegalese' },
  { value: 'Serbian', label: 'Serbian' },
  { value: 'Seychellois', label: 'Seychellois' },
  { value: 'Sierra Leonean', label: 'Sierra Leonean' },
  { value: 'Singaporean', label: 'Singaporean' },
  { value: 'Slovak', label: 'Slovak' },
  { value: 'Slovenian', label: 'Slovenian' },
  { value: 'Solomon Islander', label: 'Solomon Islander' },
  { value: 'Somali', label: 'Somali' },
  { value: 'South African', label: 'South African' },
  { value: 'South Korean', label: 'South Korean' },
  { value: 'South Sudanese', label: 'South Sudanese' },
  { value: 'Spanish', label: 'Spanish' },
  { value: 'Sri Lankan', label: 'Sri Lankan' },
  { value: 'Sudanese', label: 'Sudanese' },
  { value: 'Surinamer', label: 'Surinamer' },
  { value: 'Swazi', label: 'Swazi' },
  { value: 'Swedish', label: 'Swedish' },
  { value: 'Swiss', label: 'Swiss' },
  { value: 'Syrian', label: 'Syrian' },
  { value: 'Taiwanese', label: 'Taiwanese' },
  { value: 'Tajik', label: 'Tajik' },
  { value: 'Tanzanian', label: 'Tanzanian' },
  { value: 'Thai', label: 'Thai' },
  { value: 'Togolese', label: 'Togolese' },
  { value: 'Tongan', label: 'Tongan' },
  { value: 'Trinidadian', label: 'Trinidadian' },
  { value: 'Tunisian', label: 'Tunisian' },
  { value: 'Turkish', label: 'Turkish' },
  { value: 'Turkmen', label: 'Turkmen' },
  { value: 'Tuvaluan', label: 'Tuvaluan' },
  { value: 'Ugandan', label: 'Ugandan' },
  { value: 'Ukrainian', label: 'Ukrainian' },
  { value: 'Uruguayan', label: 'Uruguayan' },
  { value: 'Uzbekistani', label: 'Uzbekistani' },
  { value: 'Vanuatuan', label: 'Vanuatuan' },
  { value: 'Venezuelan', label: 'Venezuelan' },
  { value: 'Vietnamese', label: 'Vietnamese' },
  { value: 'Welsh', label: 'Welsh' },
  { value: 'Yemeni', label: 'Yemeni' },
  { value: 'Zambian', label: 'Zambian' },
  { value: 'Zimbabwean', label: 'Zimbabwean' }
];

const calculateAge = (dateOfBirth) => {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

function ProfileForm() {
  const [profile, setProfile] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    dateOfBirth: '',
    nationality: '',
    languages: [], // Initialiser avec un tableau vide
    bio: '', // Ajouter la bio
    interests: '',
    favoriteDestinations: [],
    nextTrip: '',
    travelType: '',
    stayDuration: '',
    meetGoals: '',
    pastTrips: [],
    hobbies: '',
    dietaryRestrictions: '',
    profilePicture: '',
  });


  const [profilePictureFile, setProfilePictureFile] = useState(null);
  const [profilePicturePreview, setProfilePicturePreview] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);

  const { currentUser } = useAuth();
  const db = getFirestore();
  const storage = getStorage();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userProfile = userDoc.data().profile || {};
          setProfile({
            ...userProfile,
          });
          if (userProfile.profilePicture) {
            setProfilePicturePreview(userProfile.profilePicture);
          }
        }
      }
    };

    fetchProfile();
  }, [currentUser, db]);

  useEffect(() => {
    const checkFormValidity = () => {
      const {
        firstName,
        lastName,
        gender,
        dateOfBirth,
        nationality,
        languages = [], // Utiliser une valeur par défaut de tableau vide
        bio,
      } = profile;
  
      const isValid =
        firstName &&
        lastName &&
        gender &&
        dateOfBirth &&
        nationality &&
        (languages.length > 0) && // Assurez-vous que languages est toujours défini comme un tableau
        bio &&
        (profilePictureFile || profile.profilePicture);
  
      setIsFormValid(isValid);
    };
  
    checkFormValidity();
  }, [profile, profilePictureFile]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handleMultiSelectChange = (selectedOptions, actionMeta) => {
    setProfile({ ...profile, [actionMeta.name]: selectedOptions.map(option => option.value) });
  };

  const handleNationalityChange = (selectedOption) => {
    setProfile({ ...profile, nationality: selectedOption ? selectedOption.value : '' });
  };

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicturePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setProfilePictureFile(file); // Stocker le fichier image
    }
  };

  const handleProfilePictureSubmit = async (e) => {
    e.preventDefault();

    if (!profilePictureFile) {
      alert('Please select a profile picture.');
      return;
    }

    const file = profilePictureFile;
    const storageRef = ref(storage, `profile_pictures/${currentUser.uid}/${file.name}`);
    await uploadBytes(storageRef, file);
    const profilePictureUrl = await getDownloadURL(storageRef);

    if (currentUser) {
      const userDocRef = doc(db, 'users', currentUser.uid);
      await setDoc(userDocRef, {
        profile: { ...profile, profilePicture: profilePictureUrl }
      }, { merge: true });
      alert('Profile picture updated successfully!');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const age = calculateAge(profile.dateOfBirth);

    let profilePictureUrl = profile.profilePicture || '';
    if (profilePictureFile) {
      const file = profilePictureFile;
      const storageRef = ref(storage, `profile_pictures/${currentUser.uid}/${file.name}`);
      await uploadBytes(storageRef, file);
      profilePictureUrl = await getDownloadURL(storageRef);
    }

    if (currentUser) {
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      const profileWithoutFile = { ...profile };
      delete profileWithoutFile.profilePictureFile;

      const isFirstProfileUpdate = !userDoc.data().isProfileComplete;

      await setDoc(userDocRef, {
        profile: { ...profileWithoutFile, age, profilePicture: profilePictureUrl },
        name: `${profile.firstName} ${profile.lastName}`,
        isProfileComplete: true,
      }, { merge: true });

      const updateUserLocation = () => {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            await updateDoc(userDocRef, {
              lat: latitude,
              lng: longitude,
              onlineStatus: 'online',
              lastLogin: new Date()
            });
            alert('Profile and location updated successfully!');
            navigate('/accueil');
          },
          (error) => {
            console.error('Geolocation error:', error);
            alert('Please enable geolocation to continue.');
          }
        );
      };

      if (isFirstProfileUpdate) {
        updateUserLocation();
      } else {
        alert('Profile updated successfully!');
      }
    } else {
      alert('User not authenticated');
    }
  };

  return (
    <div className="profile-form-container">
      <h1 className="profile-title">My Profile</h1>
      <form onSubmit={handleProfilePictureSubmit} className="profile-form">
        <div className="form-group">
          <label htmlFor="profilePicture">Profile Picture <span className="required">*</span></label>
          <input 
            type="file" 
            id="profilePicture" 
            className="form-control" 
            accept="image/*" 
            onChange={handleProfilePictureChange} 
            required 
          />
          {profilePicturePreview && (
            <div className="profile-picture-preview">
              <img src={profilePicturePreview} alt="Profile Preview" className="rounded-circle" />
            </div>
          )}
        </div>
        <button type="submit" className="btn btn-primary">Update Profile Picture</button>
      </form>

      <form onSubmit={handleSubmit} className="profile-form">
        {/* Champs du formulaire */}
        <div className="form-group">
          <label htmlFor="firstName">Firstname <span className="required">*</span></label>
          <input 
            type="text" 
            id="firstName" 
            name="firstName" 
            className="form-control" 
            value={profile.firstName} 
            onChange={handleChange} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="lastName">Lastname <span className="required">*</span></label>
          <input 
            type="text" 
            id="lastName" 
            name="lastName" 
            className="form-control" 
            value={profile.lastName} 
            onChange={handleChange} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="gender">Gender <span className="required">*</span></label>
          <select 
            id="gender" 
            name="gender" 
            className="form-control" 
            value={profile.gender} 
            onChange={handleChange} 
            required
          >
            <option value="">Make your selection</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="dateOfBirth">Date of birth <span className="required">*</span></label>
          <input 
            type="date" 
            id="dateOfBirth" 
            name="dateOfBirth" 
            className="form-control" 
            value={profile.dateOfBirth} 
            onChange={handleChange} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="nationality">Nationality <span className="required">*</span></label>
          <Select
            id="nationality"
            name="nationality"
            options={nationalities}
            value={nationalities.find(option => option.value === profile.nationality)}
            onChange={handleNationalityChange}
            isClearable
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="languages">Spoken languages <span className="required">*</span></label>
          <Select
  id="languages"
  name="languages"
  options={languages}
  isMulti
  value={languages.filter(option => (profile.languages || []).includes(option.value))} // Vérification sécurisée
  onChange={handleMultiSelectChange}
  required
/>
        </div>
        <div className="form-group">
  <label htmlFor="bio">Bio - Willingness at the moment <span className="required">*</span></label>
  <textarea
    id="bio"
    name="bio"
    className="form-control"
    value={profile.bio}
    onChange={handleChange}
    required
  />
</div>
        <div className="form-group">
          <label htmlFor="interests">Interests </label>
          <input 
            type="text" 
            id="interests" 
            name="interests" 
            className="form-control" 
            value={profile.interests} 
            onChange={handleChange} 
          />
        </div>
        <div className="form-group">
          <label htmlFor="favoriteDestinations">Favorite destinations </label>
          <Select
  id="favoriteDestinations"
  name="favoriteDestinations"
  options={countries}
  isMulti
  value={countries.filter(option => (profile.favoriteDestinations || []).includes(option.value))} // Vérification sécurisée
  onChange={handleMultiSelectChange}
/>
        </div>
        <div className="form-group">
          <label htmlFor="nextTrip">Next planned trip </label>
          <input 
            type="text" 
            id="nextTrip" 
            name="nextTrip" 
            className="form-control" 
            value={profile.nextTrip} 
            onChange={handleChange} 
          />
        </div>
        <div className="form-group">
          <label htmlFor="travelType">Preferred type of trip </label>
          <input 
            type="text" 
            id="travelType" 
            name="travelType" 
            className="form-control" 
            value={profile.travelType} 
            onChange={handleChange} 
          />
        </div>
        <div className="form-group">
          <label htmlFor="stayDuration">Usual length of stay </label>
          <input 
            type="text" 
            id="stayDuration" 
            name="stayDuration" 
            className="form-control" 
            value={profile.stayDuration} 
            onChange={handleChange} 
          />
        </div>
        <div className="form-group">
          <label htmlFor="meetGoals">Dating goals </label>
          <input 
            type="text" 
            id="meetGoals" 
            name="meetGoals" 
            className="form-control" 
            value={profile.meetGoals} 
            onChange={handleChange} 
          />
        </div>
        <div className="form-group">
          <label htmlFor="pastTrips">Reference from past trips </label>
          <Select
  id="pastTrips"
  name="pastTrips"
  options={countries}
  isMulti
  value={countries.filter(option => (profile.pastTrips || []).includes(option.value))} // Vérification sécurisée
  onChange={handleMultiSelectChange}
/>
        </div>
        <div className="form-group">
          <label htmlFor="hobbies">Planned hobbies and activities </label>
          <input 
            type="text" 
            id="hobbies" 
            name="hobbies" 
            className="form-control" 
            value={profile.hobbies} 
            onChange={handleChange} 
          />
        </div>
        <div className="form-group">
          <label htmlFor="dietaryRestrictions">Dietary restrictions or allergies</label>
          <input 
            type="text" 
            id="dietaryRestrictions" 
            name="dietaryRestrictions" 
            className="form-control" 
            value={profile.dietaryRestrictions} 
            onChange={handleChange} 
          />
        </div>
        <button type="submit" className="btn btn-primary" disabled={!isFormValid}>Update Profile</button>
      </form>
    </div>
  );
}

export default ProfileForm;