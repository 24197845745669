// src/components/AccountPage.js
import React from 'react';
import TwoFactorAuth from './TwoFactorAuth'; // Importer le composant TwoFactorAuth
import './AccountPage.css';

function AccountPage({ user, setUser }) {
  return (
    <div className="account-page">
      <h2>Mon Compte</h2>
      <form>
        <div className="form-group">
          <label htmlFor="username">Nom d&apos;utilisateur</label>
          <input type="text" id="username" name="username" className="form-control" />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" className="form-control" />
        </div>
        <div className="form-group">
          <label htmlFor="password">Mot de passe</label>
          <input type="password" id="password" name="password" className="form-control" />
        </div>
        <button type="submit" className="btn btn-primary">Mettre à jour</button>
      </form>
      <TwoFactorAuth user={user} setUser={setUser} /> {/* Ajouter le composant TwoFactorAuth */}
    </div>
  );
}

export default AccountPage;