import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { useAuth } from '../firebaseConfig';
import './FavoriteGroupsPage.css';

function FavoriteGroupsPage() {
  const navigate = useNavigate();
  const [favoriteGroups, setFavoriteGroups] = useState([]);
  const { currentUser } = useAuth();
  const db = getFirestore();

  useEffect(() => {
    if (currentUser) {
      const fetchFavorites = async () => {
        try {
          const favoritesCollection = collection(db, `users/${currentUser.uid}/favorites`);
          const favoritesSnapshot = await getDocs(favoritesCollection);
          const favoritesData = favoritesSnapshot.docs.map(doc => doc.data());
          setFavoriteGroups(favoritesData);
        } catch (error) {
          console.error("Error fetching favorite groups: ", error);
        }
      };

      fetchFavorites();
    }
  }, [currentUser, db]);

  const handleGroupClick = (group) => {
    const { chat_id, country, city, name } = group;

    // Sauvegarder les sélections dans le localStorage
    localStorage.setItem('selectedCountry', country);
    localStorage.setItem('selectedCity', JSON.stringify({ name: city }));
    localStorage.setItem('selectedGroup', JSON.stringify({ chat_id, name }));

    // Rediriger vers la page CountryCityList
    navigate('/countrycitylist');
  };

  return (
    <div className="favorite-groups-page">
      <h1>My Favorite forum groups</h1>
      <ul className="favorite-groups-list">
        {favoriteGroups.map((group, index) => (
          <li key={index}>
            <button
              onClick={() => handleGroupClick(group)}
              className="favorite-group-button"
            >
              {group.groupName} à {group.city}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FavoriteGroupsPage;