import React, { useState } from 'react';
import './RatingComponent.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faHandshake, faComments, faMapMarkerAlt, faStar, faThumbsUp, faRedo, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const RatingComponent = ({ meetingId, onSubmit }) => {
  const [rating, setRating] = useState({
    punctuality: 3,
    respect: 3,
    conversationQuality: 3,
    meetingPlace: 3,
    overallExperience: 3,
    conversationDescriptors: [],
    positiveFeedback: [],
    meetAgain: 'yes',
    issueReported: 'no', // Default to 'no'
    issueDescription: '', // Optional field for issue description
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const newValue = parseInt(value);
    setRating((prevRating) => ({
      ...prevRating,
      [name]: name === 'meetAgain' ? value : newValue >= 3 ? newValue : 3, // Ensure minimum rating is 3
    }));
  };

  const handleSelectChange = (event, field) => {
    const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
    setRating((prevRating) => ({
      ...prevRating,
      [field]: selectedOptions,
    }));
  };

  const handleIssueChange = (event) => {
    const { name, value } = event.target;
    setRating((prevRating) => ({
      ...prevRating,
      [name]: value,
      ...(name === 'issueReported' && value === 'no' ? { issueDescription: '' } : {}), // Clear issueDescription if 'no'
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Submitting Rating:", rating);
    if (!rating.punctuality || !rating.respect || !rating.conversationQuality || !rating.meetingPlace || !rating.overallExperience) {
        alert('All mandatory fields must be filled.');
        return;
    }
    console.log("Passing to onSubmit:", { meetingId, rating });
    onSubmit(meetingId, rating);  // Transmet les données de rating correctement
};

  const conversationDescriptorsOptions = [
    'Engaging',
    'Interesting',
    'Relaxed',
    'Deep',
    'Humorous',
    'Serious',
    'Inspiring',
    'Comforting',
    'Balanced',
    'Empathetic',
  ];

  const positiveFeedbackOptions = [
    'Good listener',
    'Very friendly',
    'Excellent communicator',
    'Respectful',
    'Open-minded',
    'Warm',
    'Positive',
    'Energetic',
    'Supportive',
    'Inspiring',
    'Kind',
    'Sociable',
  ];

  return (
    <form onSubmit={handleSubmit} className="rating-form">
      <div className="rating-field">
        <label htmlFor="punctuality">
          <FontAwesomeIcon icon={faClock} /> Punctuality
        </label>
        <input
          id="punctuality"
          type="range"
          name="punctuality"
          min="3"
          max="5"
          value={rating.punctuality}
          onChange={handleInputChange}
          aria-label="Punctuality"
        />
        <span className="rating-value">{rating.punctuality}</span>
      </div>
      <div className="rating-field">
        <label htmlFor="respect">
          <FontAwesomeIcon icon={faHandshake} /> Respect
        </label>
        <input
          id="respect"
          type="range"
          name="respect"
          min="3"
          max="5"
          value={rating.respect}
          onChange={handleInputChange}
          aria-label="Respect"
        />
        <span className="rating-value">{rating.respect}</span>
      </div>
      <div className="rating-field">
        <label htmlFor="conversationQuality">
          <FontAwesomeIcon icon={faComments} /> Conversation Quality
        </label>
        <input
          id="conversationQuality"
          type="range"
          name="conversationQuality"
          min="3"
          max="5"
          value={rating.conversationQuality}
          onChange={handleInputChange}
          aria-label="Conversation Quality"
        />
        <span className="rating-value">{rating.conversationQuality}</span>
      </div>

      <div className="rating-field">
        <label htmlFor="conversationDescriptors">
          <FontAwesomeIcon icon={faStar} /> Conversation Descriptors
        </label>
        <select
          id="conversationDescriptors"
          name="conversationDescriptors"
          multiple
          value={rating.conversationDescriptors}
          onChange={(e) => handleSelectChange(e, 'conversationDescriptors')}
        >
          {conversationDescriptorsOptions.map((descriptor) => (
            <option key={descriptor} value={descriptor}>
              {descriptor}
            </option>
          ))}
        </select>
      </div>

      <div className="rating-field">
        <label htmlFor="meetingPlace">
          <FontAwesomeIcon icon={faMapMarkerAlt} /> Meeting Place
        </label>
        <input
          id="meetingPlace"
          type="range"
          name="meetingPlace"
          min="3"
          max="5"
          value={rating.meetingPlace}
          onChange={handleInputChange}
          aria-label="Meeting Place"
        />
        <span className="rating-value">{rating.meetingPlace}</span>
      </div>
      <div className="rating-field">
        <label htmlFor="overallExperience">
          <FontAwesomeIcon icon={faStar} /> Overall Experience
        </label>
        <input
          id="overallExperience"
          type="range"
          name="overallExperience"
          min="3"
          max="5"
          value={rating.overallExperience}
          onChange={handleInputChange}
          aria-label="Overall Experience"
        />
        <span className="rating-value">{rating.overallExperience}</span>
      </div>

      <div className="rating-field">
        <label htmlFor="positiveFeedback">
          <FontAwesomeIcon icon={faThumbsUp} /> Positive Feedback
        </label>
        <select
          id="positiveFeedback"
          name="positiveFeedback"
          multiple
          value={rating.positiveFeedback}
          onChange={(e) => handleSelectChange(e, 'positiveFeedback')}
        >
          {positiveFeedbackOptions.map((feedback) => (
            <option key={feedback} value={feedback}>
              {feedback}
            </option>
          ))}
        </select>
      </div>

      <div className="rating-field">
        <label htmlFor="meetAgain">
          <FontAwesomeIcon icon={faRedo} /> Would you meet again?
        </label>
        <select id="meetAgain" name="meetAgain" value={rating.meetAgain} onChange={handleInputChange}>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </div>

      <div className="rating-field">
        <label htmlFor="issueReported">
          <FontAwesomeIcon icon={faExclamationTriangle} /> Report an Issue?
        </label>
        <select id="issueReported" name="issueReported" value={rating.issueReported} onChange={handleIssueChange}>
          <option value="no">No</option>
          <option value="yes">Yes</option>
        </select>
      </div>

      {rating.issueReported === 'yes' && (
        <div className="rating-field">
          <label htmlFor="issueDescription">Issue Description</label>
          <textarea
            id="issueDescription"
            name="issueDescription"
            value={rating.issueDescription}
            onChange={handleIssueChange}
            placeholder="Describe the issue here..."
            aria-label="Issue Description"
          />
        </div>
      )}

      <button type="submit" className="submit-button">Submit</button>
    </form>
  );
};

export default RatingComponent;