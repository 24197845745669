import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, onSnapshot, collection, updateDoc, setDoc, arrayUnion } from 'firebase/firestore';
import { useAuth } from '../firebaseConfig';
import RatingComponent from '../components/RatingComponent';
import './RatingPage.css';

function RatingPage() {
  const { currentUser } = useAuth();
  const [meetings, setMeetings] = useState([]);

  useEffect(() => {
    if (currentUser) {
      const db = getFirestore();
      const meetingsRef = collection(db, `users/${currentUser.uid}/meetings`);

      const unsubscribeMeetings = onSnapshot(meetingsRef, async (snapshot) => {
        const meetingsData = await Promise.all(
          snapshot.docs.map(async (docSnapshot) => {
            const meeting = docSnapshot.data();
            const otherUserId = meeting.senderId === currentUser.uid ? meeting.receiverId : meeting.senderId;
            const otherUserDoc = await getDoc(doc(db, 'users', otherUserId));
            let otherUser = { name: 'Unknown User', profilePicture: '' };
            if (otherUserDoc.exists()) {
              const userData = otherUserDoc.data();
              otherUser = {
                name: `${userData.profile.firstName} ${userData.profile.lastName.charAt(0)}.`,
                profilePicture: userData.profile.profilePicture || '',
              };
            }

            // Vérifiez si la rencontre est confirmée et en attente d'évaluation
            if (meeting.status === 'confirmed' && meeting.ratingPending) {
              const userDocRef = doc(db, 'users', currentUser.uid);

              const userDoc = await getDoc(userDocRef);
              const userNotifications = userDoc.exists() ? userDoc.data().notifications || [] : [];

              const hasRatingNotificationForUser = userNotifications.some(notification => 
                notification.type === 'rating_pending' && 
                notification.meetingId === meeting.meetingId
              );

              if (!hasRatingNotificationForUser) {
                await updateDoc(userDocRef, {
                  notifications: arrayUnion({
                    type: 'rating_pending',
                    message: `Please rate your meeting with ${otherUser.name}.`,
                    meetingId: meeting.meetingId,
                    timestamp: new Date(),
                    read: false,
                  })
                });
              }
            }

            return {
              ...meeting,
              otherUser,
              otherUserId,
            };
          })
        );
        setMeetings(meetingsData);
      });

      return () => unsubscribeMeetings();
    }
  }, [currentUser]);

  const handleRatingSubmit = async (meetingId, ratingData) => {
    console.log("Rating Data:", ratingData);

    if (
      !ratingData ||
      !ratingData.punctuality ||
      !ratingData.respect ||
      !ratingData.conversationQuality ||
      !ratingData.meetingPlace ||
      !ratingData.overallExperience
    ) {
      alert('All mandatory fields must be filled.');
      return;
    }

    const db = getFirestore();
    const meetingRef = doc(db, `users/${currentUser.uid}/meetings`, meetingId);
    const otherUserId = meetings.find(meeting => meeting.meetingId === meetingId)?.otherUserId;

    try {
      const sanitizedRatingData = {
        punctuality: ratingData.punctuality,
        respect: ratingData.respect,
        conversationQuality: ratingData.conversationQuality,
        meetingPlace: ratingData.meetingPlace,
        overallExperience: ratingData.overallExperience,
        positiveFeedback: ratingData.positiveFeedback || [],
        conversationDescriptors: ratingData.conversationDescriptors || [],
        meetAgain: ratingData.meetAgain,
        issueReported: ratingData.issueReported,
        issueDescription: ratingData.issueReported === 'yes' ? ratingData.issueDescription : '',
      };

      const currentUserSentEvaluationsRef = collection(db, `users/${currentUser.uid}/sentEvaluations`);
      const otherUserReceivedEvaluationsRef = collection(db, `users/${otherUserId}/receivedEvaluations`);

      const currentUserEvaluationDocRef = doc(currentUserSentEvaluationsRef, meetingId);
      const otherUserEvaluationDocRef = doc(otherUserReceivedEvaluationsRef, meetingId);

      await setDoc(currentUserEvaluationDocRef, sanitizedRatingData);
      await setDoc(otherUserEvaluationDocRef, sanitizedRatingData);

      await updateDoc(meetingRef, {
        ratingPending: false,
      });

      const otherMeetingRef = doc(db, `users/${otherUserId}/meetings`, meetingId);
      await updateDoc(otherMeetingRef, {
        ratingPending: false,
      });

      const removeRatingNotification = async (userRef) => {
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const notifications = userDoc.data().notifications || [];
          const updatedNotifications = notifications.filter(
            notification => notification.type !== 'rating_pending' || notification.meetingId !== meetingId
          );

          await updateDoc(userRef, {
            notifications: updatedNotifications
          });
        }
      };

      const userDocRef = doc(db, 'users', currentUser.uid);
      const otherUserDocRef = doc(db, 'users', otherUserId);

      await removeRatingNotification(userDocRef);
      await removeRatingNotification(otherUserDocRef);

      alert('Thank you for your feedback!');

      setMeetings((prevMeetings) =>
        prevMeetings.map((meeting) =>
          meeting.meetingId === meetingId ? { ...meeting, ratingPending: false } : meeting
        )
      );

    } catch (error) {
      console.error('Error submitting feedback:', error);
      alert('There was an error submitting your feedback. Please try again later.');
    }
  };

  return (
    <div className="rating-page">
      <h1>Meeting Evaluations</h1>
      <div className="rating-section">
        {meetings.filter(meeting => meeting.status === 'confirmed' && meeting.ratingPending).length > 0 ? (
          <ul>
            {meetings.filter(meeting => meeting.status === 'confirmed' && meeting.ratingPending).map((meeting, index) => (
              <li key={index} className="rating-item">
                <div className="meeting-info">
                  <img src={meeting.otherUser.profilePicture} alt={`${meeting.otherUser.name} profile`} className="profile-picture" />
                  <div>
                    <h3>{meeting.otherUser.name}</h3>
                    <p>Meeting confirmed, please rate:</p>
                  </div>
                </div>
                <div className="rating-component-wrapper">
                  <RatingComponent
                    meetingId={meeting.meetingId}
                    onSubmit={handleRatingSubmit}
                  />
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No pending evaluations.</p>
        )}
      </div>
    </div>
  );
}

export default RatingPage;