// src/components/initiateMeeting.js
import { getFirestore, doc, setDoc, updateDoc, getDoc, arrayUnion } from "firebase/firestore";

export const initiateMeeting = async (currentUser, otherUserId, setMeetingStatus) => {
  if (!currentUser || !otherUserId) {
    throw new Error("Invalid user data. Please make sure both users are defined.");
  }

  const db = getFirestore();

  const meetingId = `${currentUser.uid}_${otherUserId}_${Date.now()}`;

  const meetingData = {
    meetingId,
    senderId: currentUser.uid,
    receiverId: otherUserId,
    status: "pending",
    confirmedBySender: false,
    confirmedByReceiver: false,
    meetPointsAwarded: false,
    meetPointsStatus: "pending",  // Nouveau champ pour le statut des MeetPoints
    senderReview: null,
    receiverReview: null,
    senderStatus: "waiting_for_receiver", 
    receiverStatus: "pending_response",   
  };

  // Ajouter la rencontre dans la sous-collection `meetings` des deux utilisateurs
  await setDoc(doc(db, `users/${currentUser.uid}/meetings`, meetingId), meetingData);
  await setDoc(doc(db, `users/${otherUserId}/meetings`, meetingId), meetingData);

  // Initialiser les meetPoints pour les deux utilisateurs si non existants
  const currentUserRef = doc(db, `users/${currentUser.uid}`);
  const otherUserRef = doc(db, `users/${otherUserId}`);

  const currentUserDoc = await getDoc(currentUserRef);
  const otherUserDoc = await getDoc(otherUserRef);

  if (!currentUserDoc.exists() || !('meetPoints' in currentUserDoc.data())) {
    await updateDoc(currentUserRef, { meetPoints: 0 });
  }

  if (!otherUserDoc.exists() || !('meetPoints' in otherUserDoc.data())) {
    await updateDoc(otherUserRef, { meetPoints: 0 });
  }

  console.log(`Meeting initiated between ${currentUser.uid} and ${otherUserId}`);

  // Mettre à jour le statut du bouton
  if (setMeetingStatus) {
    setMeetingStatus('pending');
  }

  // Ajouter une notification pour l'autre utilisateur
  await updateDoc(otherUserRef, {
    notifications: arrayUnion({
      type: 'meeting_initiated',
      from: currentUser.uid,
      to: otherUserId,
      meetingId: meetingId,
      timestamp: new Date(),
      message: `${currentUser.profile?.firstName || 'Un utilisateur'} souhaite valider une rencontre avec vous.`,
      read: false
    }),
  });
};