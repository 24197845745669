// src/pages/ChatPage.js
import React from 'react';
import ChatRoom from '../components/ChatRoom';
import './ChatPage.css';

function ChatPage({ user }) {
  return (
    <div className="chat-page">
      <h2 className="chat-title">Chat Room</h2>
      <ChatRoom user={user} />
    </div>
  );
}

export default ChatPage;