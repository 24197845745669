import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, updateDoc, arrayUnion, arrayRemove, collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { useAuth } from '../firebaseConfig';
import './UserProfilePublic.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMars, faVenus, faGenderless, faBirthdayCake, faFlag, faLanguage, faMapMarkerAlt, faHeart, faUtensils, faPlane, faSuitcaseRolling, faUser, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';


async function fetchAccessToken() {
  const response = await fetch('https://mail.meevel.app/get_access_token.php');
  const data = await response.json();
  return data.accessToken;
}


function UserProfilePublic() {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [requestPending, setRequestPending] = useState(false);
  const { currentUser } = useAuth();
  const [isFriend, setIsFriend] = useState(false);
  const [receivedRequest, setReceivedRequest] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      if (!currentUser) return;

      const db = getFirestore();
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();

        // Récupérer les évaluations reçues
        const evaluationsSnapshot = await getDocs(collection(db, `users/${userId}/receivedEvaluations`));
        userData.receivedEvaluations = evaluationsSnapshot.docs.map((evalDoc) => evalDoc.data());

        setUser(userData);

        const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (currentUserDoc.exists()) {
          const currentUserData = currentUserDoc.data();
          setIsFriend(currentUserData.friends && currentUserData.friends.includes(userId));
          setRequestPending(currentUserData.sentRequests && currentUserData.sentRequests.includes(userId));
          setReceivedRequest(currentUserData.receivedRequests && currentUserData.receivedRequests.includes(userId));
        }
      } else {
        console.error('Utilisateur non trouvé');
      }
    };

    fetchUser();
  }, [userId, currentUser]);

  const calculateAge = (dateOfBirth) => {
    if (!dateOfBirth) return 'N/A';
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handleConnect = async () => {
    if (!currentUser) return;
  
    const db = getFirestore();
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
  
    if (userDoc.exists()) {
      const userData = userDoc.data();
      const updatedNotifications = [
        ...(userData.notifications || []),
        {
          type: 'friend_request',
          from: currentUser.uid,
          timestamp: new Date(),
        },
      ];
      await updateDoc(userDocRef, { notifications: updatedNotifications });
  
      const currentUserDocRef = doc(db, 'users', currentUser.uid);
      const currentUserDoc = await getDoc(currentUserDocRef);
      const currentUserData = currentUserDoc.data();
  
      // Envoi de l'e-mail lors de la demande d'ami
      await fetch('https://mail.meevel.app/send_email.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          'email': userData.email,  // L'e-mail du destinataire (l'utilisateur qui reçoit la demande)
          'username': `${currentUserData.profile.firstName} ${currentUserData.profile.lastName.charAt(0)}`,  // Le nom de l'utilisateur qui envoie la demande
          'type': 'friend_request',  // Spécification du type d'e-mail
        }),
      });

      const accessToken = await fetchAccessToken();

await fetch('https://mail.meevel.app/send_fcm_notification.php', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  body: new URLSearchParams({
    'deviceToken': userData.deviceToken,  // Le token de l'utilisateur cible
    'accessToken': accessToken,           // Le jeton d'accès pour l'authentification
    'title': 'You’ve Got a New Friend Request on Meevel! 👋',
    'body': `${currentUserData.profile.firstName} ${currentUserData.profile.lastName.charAt(0)} has sent you a friend request on Meevel!`,
  }),
});
  
      // Mettre à jour les demandes d'amis dans Firestore
      await updateDoc(currentUserDocRef, {
        sentRequests: arrayUnion(userId),
      });
  
      await updateDoc(userDocRef, {
        receivedRequests: arrayUnion(currentUser.uid),
      });
  
      setRequestPending(true);
    } else {
      console.error('Utilisateur non trouvé');
    }
  };

  const handleAcceptRequest = async () => {
    if (!currentUser) return;

    const db = getFirestore();

    await updateDoc(doc(db, 'users', currentUser.uid), {
      friends: arrayUnion(userId),
      receivedRequests: arrayRemove(userId),
    });

    await updateDoc(doc(db, 'users', userId), {
      friends: arrayUnion(currentUser.uid),
      sentRequests: arrayRemove(currentUser.uid),
    });

    setIsFriend(true);
    setRequestPending(false);
    setReceivedRequest(false);
  };

  const handleMessage = async () => {
    if (!currentUser) return;

    const db = getFirestore();

    const chatQuery = query(
      collection(db, 'chats'),
      where('members', 'array-contains', currentUser.uid)
    );

    const chatSnapshot = await getDocs(chatQuery);
    let existingChatId = null;

    chatSnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.members.includes(userId)) {
        existingChatId = doc.id;
      }
    });

    if (existingChatId) {
      navigate(`/chat/${existingChatId}`);
    } else {
      const newChatRef = await addDoc(collection(db, 'chats'), {
        members: [currentUser.uid, userId],
        lastMessage: null,
        readBy: { [currentUser.uid]: true, [userId]: false },
      });
      navigate(`/chat/${newChatRef.id}`);
    }
  };

  useEffect(() => {
    const checkFriendStatus = async () => {
      if (!currentUser) return;

      const db = getFirestore();
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const isNowFriend = userData.friends && userData.friends.includes(currentUser.uid);
        setIsFriend(isNowFriend);

        if (isNowFriend) {
          await updateDoc(doc(db, 'users', currentUser.uid), {
            sentRequests: arrayRemove(userId),
          });
          await updateDoc(userDocRef, {
            receivedRequests: arrayRemove(currentUser.uid),
          });
          setRequestPending(false);
        }
      }
    };

    checkFriendStatus();
  }, [userId, currentUser]);

  const getPositiveFeedback = (user) => {
    if (user?.receivedEvaluations?.length > 0) {
      const positiveFeedbacks = user.receivedEvaluations.flatMap(
        (evaluation) => evaluation.positiveFeedback || []
      );
      if (positiveFeedbacks.length > 0) {
        return positiveFeedbacks[0]; // Afficher le premier feedback positif
      }
    }
    return null;
  };

  const formatLastLogin = (lastLogin) => {
    if (!lastLogin || !lastLogin.seconds) return 'N/A';
    const date = new Date(lastLogin.seconds * 1000);
    return date.toLocaleString();
  };

  if (!user) {
    return <div>Chargement...</div>;
  }

  return (
    <div className="user-profile-public">
      <div className="top-bar">
        <Link to="/user-map" className="back-button">Back</Link>
        {!isFriend && !requestPending && !receivedRequest && (
          <button className="connect-button" onClick={handleConnect}>Connect to this traveller</button>
        )}
        {requestPending && !isFriend && !receivedRequest && (
          <button className="pending-button" disabled>Demand in request</button>
        )}
        {receivedRequest && !isFriend && (
          <button className="accept-button" onClick={handleAcceptRequest}>Accept friend request</button>
        )}
        {isFriend && (
          <button className="message-button" onClick={handleMessage}>Texter avec ce voyageur</button>
        )}
      </div>
      <div className="profile-header">
        <img
          src={user?.profile?.profilePicture || 'path_to_default_image.jpg'}
          alt={`${user?.profile?.firstName || "Unknown"} ${user?.profile?.lastName?.charAt(0) || ""}`}
          className="user-profile-photo"
        />
        <h1>{user?.profile?.firstName || "Unknown"} {user?.profile?.lastName?.charAt(0) || ""}.</h1>
        <div className="status-indicator">
          <span className={`status-dot ${user.onlineStatus === "online" ? "online" : "offline"}`}></span>
          <span className="status-text">
            {user.onlineStatus === "online" ? "Connected" : `Offline from ${formatLastLogin(user.lastLogin)}`}
          </span>
        </div>
        {getPositiveFeedback(user) && (
          <div className="feedback-badge">
            {getPositiveFeedback(user)}
          </div>
        )}
        {user.meetPoints && (
          <div className="meetpoints-badge">
            <span className="star-icon">★</span>
            <span className="meetpoints-number">{user.meetPoints}</span> Meetpoints
          </div>
        )}
      </div>
      <div className="profile-section">
        <h2><FontAwesomeIcon icon={faUser} /> Profile</h2>
        <div className="profile-text">
          <p>
            <FontAwesomeIcon icon={user?.profile?.gender === 'Male' ? faMars : user?.profile?.gender === 'Female' ? faVenus : faGenderless} />{' '}
            Gender: {user?.profile?.gender || 'N/A'}
          </p>
          <p>
            <FontAwesomeIcon icon={faBirthdayCake} /> Age: {calculateAge(user?.profile?.dateOfBirth)}
          </p>
          <p>
            <FontAwesomeIcon icon={faFlag} /> Nationality: {user?.profile?.nationality || 'N/A'}
          </p>
          <p>
            <FontAwesomeIcon icon={faLanguage} /> Languages: {user?.profile?.languages?.join(', ') || 'N/A'}
          </p>
        </div>
      </div>

      <div className="profile-section">
        <h2><FontAwesomeIcon icon={faHeart} /> Interests</h2>
        <div className="profile-text">
          <p>
            <FontAwesomeIcon icon={faMapMarkerAlt} /> Interests: {user?.profile?.interests || 'N/A'}
          </p>
          <p>
            <FontAwesomeIcon icon={faUtensils} /> Hobbies: {user?.profile?.hobbies || 'N/A'}
          </p>
          <p>
            <FontAwesomeIcon icon={faPlane} /> Dietary restrictions or allergies: {user?.profile?.dietaryRestrictions || 'N/A'}
          </p>
        </div>
      </div>

      <div className="profile-section">
        <h2><FontAwesomeIcon icon={faSuitcaseRolling} /> Travels</h2>
        <div className="profile-text">
          <p>
            <FontAwesomeIcon icon={faMapMarkerAlt} /> Favorite destinations: {user?.profile?.favoriteDestinations?.join(', ') || 'N/A'}
          </p>
          <p>
            <FontAwesomeIcon icon={faPlane} /> Next planned trip: {user?.profile?.nextTrip || 'N/A'}
          </p>
          <p>
            <FontAwesomeIcon icon={faSuitcaseRolling} /> Preferred type of trip: {user?.profile?.travelType || 'N/A'}
          </p>
          <p>
            <FontAwesomeIcon icon={faCalendarAlt} /> Usual length of stay: {user?.profile?.stayDuration || 'N/A'}
          </p>
          <p>
            <FontAwesomeIcon icon={faHeart} /> Dating goals: {user?.profile?.meetGoals || 'N/A'}
          </p>
          <p>
            <FontAwesomeIcon icon={faMapMarkerAlt} /> Reference from past trips: {user?.profile?.pastTrips?.join(', ') || 'N/A'}
          </p>
        </div>
      </div>

      <Link to="/user-map" className="back-link">Back to the user map</Link>
    </div>
  );
}

export default UserProfilePublic;