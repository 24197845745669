import React, { useState } from 'react';
import { getFirestore, collection, doc, setDoc, addDoc } from 'firebase/firestore';
import { useAuth } from '../firebaseConfig';
import './ReportAbuseModal.css';

const ReportAbuseModal = ({ onClose, reportedUserId, chatId = null }) => {  // Assurez-vous que `chatId` est facultatif
  const [selectedReason, setSelectedReason] = useState('');
  const [additionalDetails, setAdditionalDetails] = useState('');
  const { currentUser } = useAuth();

  const handleReport = async () => {
    if (!selectedReason) {
      alert('Please select a reason for the report.');
      return;
    }

    try {
      const db = getFirestore();
      const reportId = `${currentUser.uid}_${reportedUserId}_${Date.now()}`; // Identifiant unique pour le signalement
      const reportRef = doc(db, 'signalements', reportId);

      // Créer le document de signalement dans la collection `signalements`
      const reportData = {
        reporterId: currentUser.uid,
        reportedUserId,
        timestamp: new Date(),
      };

      // Inclure le `chatId` seulement s'il est fourni (pour les groupes)
      if (chatId) {
        reportData.chatId = chatId;
      }

      await setDoc(reportRef, reportData);

      // Ajouter le document dans la sous-collection `comportements` avec les détails du comportement
      const comportementRef = collection(reportRef, 'comportements');
      await addDoc(comportementRef, {
        reason: selectedReason,
        additionalDetails,
        timestamp: new Date(),
      });

      alert('Report submitted successfully.');
      onClose();
    } catch (error) {
      console.error('Error reporting abuse:', error);
      alert('Failed to submit the report. Please try again later.');
    }
  };

  return (
    <div className="report-abuse-modal">
      <div className="report-abuse-modal-content">
        <h2>Report Abuse</h2>
        <div>
          <label htmlFor="reason">Select a reason:</label>
          <select
            id="reason"
            value={selectedReason}
            onChange={(e) => setSelectedReason(e.target.value)}
          >
            <option value="">Choose a reason</option>
            <option value="harassment">Harassment</option>
            <option value="spam">Spam</option>
            <option value="hateSpeech">Hate Speech</option>
            <option value="misinformation">Misinformation</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div>
          <label htmlFor="details">Additional details (optional):</label>
          <textarea
            id="details"
            value={additionalDetails}
            onChange={(e) => setAdditionalDetails(e.target.value)}
            placeholder="Provide any additional information here..."
          />
        </div>
        <div className="report-abuse-modal-buttons">
          <button onClick={handleReport}>Submit Report</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default ReportAbuseModal;