import React, { useState, useEffect } from 'react';
import GeolocationModal from './GeolocationModal'; 
import { FaMapMarkerAlt } from 'react-icons/fa';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const Header = ({ user, onSetLocation, onUseCurrentLocation, selectedCityName }) => {
  const [isGeolocationModalOpen, setGeolocationModalOpen] = useState(false);
  const [profileName, setProfileName] = useState('');

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (user && user.uid) {
        const db = getFirestore();
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          const profile = userData.profile || {}; // Récupérer le profil de l'utilisateur

          const firstName = profile.firstName || '';
          const lastName = profile.lastName || '';
          const fullName = `${firstName} ${lastName}`.trim();

          setProfileName(fullName || 'User'); // Utilise "User" par défaut si aucun prénom/nom n'est disponible
        } else {
          console.error('No user document found.');
          setProfileName('User');
        }
      } else {
        console.error('No user or user UID available.');
        setProfileName('User');
      }
    };

    fetchUserProfile();
  }, [user]);

  const handleOpenGeolocationModal = () => {
    setGeolocationModalOpen(true);
  };

  const handleCloseGeolocationModal = () => {
    setGeolocationModalOpen(false);
  };

  return (
    <header className="header">
      <h1 className="welcome-text">
        Welcome on Meevel App <span className="user-name">{profileName}</span>
      </h1>
      <button className="geolocation-button" onClick={handleOpenGeolocationModal}>
        Set Location
      </button>
      <div className="selected-city">
        <FaMapMarkerAlt className="city-icon" />
        <span>{selectedCityName || 'No location set'}</span>
      </div>

      {isGeolocationModalOpen && (
        <GeolocationModal
          onClose={handleCloseGeolocationModal}
          onSetLocation={onSetLocation}
          onUseCurrentLocation={onUseCurrentLocation}
        />
      )}
    </header>
  );
};

export default Header;