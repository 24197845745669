import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFirestore, collection, query, where, onSnapshot, getDoc, doc, getDocs, deleteDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { useAuth } from '../firebaseConfig';
import './ConversationList.css';

function ConversationList() {
  const [conversations, setConversations] = useState([]);
  const db = getFirestore();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (!currentUser) return;

    const q = query(
      collection(db, 'chats'),
      where('members', 'array-contains', currentUser.uid)
    );

    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const convos = await Promise.all(
        querySnapshot.docs.map(async (docSnapshot) => {
          const data = docSnapshot.data();
          const otherMemberId = data.members.find(member => member !== currentUser.uid);
          const otherMemberDoc = await getDoc(doc(db, 'users', otherMemberId));
          const otherMemberData = otherMemberDoc.exists()
            ? otherMemberDoc.data()
            : { profile: { firstName: 'Utilisateur', lastName: 'Inconnu' } };

          // Vérifier si l'autre utilisateur est toujours un ami
          const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
          const isFriend = currentUserDoc.exists() && currentUserDoc.data().friends.includes(otherMemberId);
          if (!isFriend) {
            // Si l'autre utilisateur n'est plus un ami, supprimer la conversation
            await deleteDoc(doc(db, 'chats', docSnapshot.id));
            return null; // Ignorer cette conversation
          }

          // Récupérer tous les messages de la conversation envoyés par d'autres utilisateurs
          const messagesQuery = query(
            collection(db, 'messages'),
            where('chatId', '==', docSnapshot.id),
            where('senderId', '!=', currentUser.uid)
          );

          const messagesSnapshot = await getDocs(messagesQuery);
          let unreadMessagesCount = 0;

          // Filtrer les messages non lus
          messagesSnapshot.forEach(doc => {
            const messageData = doc.data();
            if (!messageData.readBy || !messageData.readBy.includes(currentUser.uid)) {
              unreadMessagesCount++;
            }
          });

          return {
            id: docSnapshot.id,
            ...data,
            otherMemberName: `${otherMemberData.profile.firstName} ${otherMemberData.profile.lastName.charAt(0)}.`,
            otherMemberId,
            unreadMessagesCount, // Nombre de messages non lus
          };
        })
      );

      // Filtrer les conversations nulles (celles qui ont été supprimées)
      setConversations(convos.filter(convo => convo !== null));
    });

    return () => unsubscribe();
  }, [currentUser, db]);

  const handleDeleteConversation = async (conversationId, otherMemberId) => {
    if (window.confirm('Are you sure you want to delete this conversation?')) {
      try {
        // Supprimer la conversation
        await deleteDoc(doc(db, 'chats', conversationId));
        setConversations(conversations.filter(convo => convo.id !== conversationId));

        // Ajouter une notification pour l'autre utilisateur
        const otherUserRef = doc(db, 'users', otherMemberId);
        await updateDoc(otherUserRef, {
          notifications: arrayUnion({
            type: 'conversation_deleted',
            from: currentUser.uid,
            timestamp: new Date(),
            message: `La conversation avec ${currentUser.displayName || 'un utilisateur'} a été supprimée.`
          })
        });
      } catch (error) {
        console.error('Error deleting conversation: ', error);
      }
    }
  };

  return (
    <div>
      {/* Titre de la page en dehors du conteneur principal */}
      <h1 className="page-title">Talks with Travellers</h1>

      <div className="conversation-list">
        <h2 className="page-subtitle">Your Recent Conversations</h2>
        <ul className="conversation-list-items">
          {conversations.map((convo) => (
            <li key={convo.id} className="conversation-list-item">
              <Link to={`/chat/${convo.id}`} className="conversation-list-link">
                {convo.otherMemberName}
                {convo.unreadMessagesCount > 0 && (
                  <span className="unread-indicator">
                    {convo.unreadMessagesCount}
                  </span>
                )}
              </Link>
              <button
                onClick={() => handleDeleteConversation(convo.id, convo.otherMemberId)}
                className="delete-button"
              >
                ✖
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}


export default ConversationList;