// src/pages/CountryListPage.js
import React from 'react';
import CountryCityList from '../components/CountryCityList';
import './CountryCityListPage.css'; // Import du fichier CSS

function CountryListPage() {
  return (
    <div>
      <CountryCityList />
    </div>
  );
}

export default CountryListPage;