// src/Pages/UserMapPage.js
import React, { useEffect, useState } from 'react';
import './UserMapPage.css';
import Map from '../components/Map'; // Chemin correct pour importer le composant Map
import usersData from '../components/users.json'; // Importer les utilisateurs fictifs

const UserMapPage = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setUsers(usersData); // Charger les utilisateurs fictifs
  }, []);

  return (
    <div className="main-content">
      <div className="map-container">
        <Map users={users} />
      </div>
    </div>
  );
};

export default UserMapPage;